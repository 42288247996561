import React, { useEffect, useState } from "react";
import "./Navv.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import logo from "../../Assets/images/logo.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import { scrollToTop } from "../Utilities/ScrollToTop";

const Navv = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [toggleNav, setToggleNav] = useState(false);

  const location = useLocation();
  const stickyNavClass = isSticky ? "navbar-sticky sticky" : "";

  const handleToggle = () => setToggleNav(!toggleNav);
  const handleClick = () => {
    setToggleNav(false);
    scrollToTop();
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsSticky(offset > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Navbar
      expand="lg"
      className={`bg-body-tertiary bggg ${stickyNavClass}`}
      expanded={toggleNav}
      onToggle={handleToggle}
    >
      <Container fluid>
        <Navbar.Brand>
          <Link to="/" onClick={scrollToTop}>
            <Image src={logo} className="logo-image d-block" alt="logo" />
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto justify-content-end">
            <Nav.Link
              as={NavLink}
              className={`nav-link-tab ${
                location.pathname === "/About" ? "active" : ""
              }`}
              onClick={handleClick}
              to="/About"
            >
              About
            </Nav.Link>

            <Nav.Link
              as={NavLink}
              className={`nav-link-tab ${
                location.pathname === "/Service" ? "active" : ""
              }`}
              onClick={handleClick}
              to="/Service"
            >
              Services
            </Nav.Link>

            <Nav.Link
              as={NavLink}
              className={`nav-link-tab ${
                location.pathname === "/Work" ? "active" : ""
              }`}
              onClick={handleClick}
              to="/Work"
            >
              Work
            </Nav.Link>

            <Nav.Link
              as={NavLink}
              className={`nav-link-tab ${
                location.pathname === "/Career" ? "active" : ""
              }`}
              onClick={handleClick}
              to="/Career"
            >
              Career
            </Nav.Link>

            <Nav.Link
              as={NavLink}
              className={`nav-link-tab ${
                location.pathname === "/Contact" ? "active" : ""
              }`}
              onClick={handleClick}
              to="/Contact"
            >
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navv;
