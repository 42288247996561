import React from "react";
import "./ExploreImage.css";
import shuffle from "lodash/sampleSize";
import Items from "../../Items/Items";
import imageData from "../../../Assets/Datas/imageData";
import { Link } from "react-router-dom";

const ExploreImage = () => {
  const contentForService = shuffle(imageData, 3).map((data) => ({
    ...data,
  }));

  return (
    <div className="container-fluid ex-new-img">
      <h2 className="mb-5">
        <Link to="/Work" target="_blank" className="works-link">
          Explore <strong>Similar Projects</strong>
        </Link>
      </h2>

      <div className="similar-projects">
        {contentForService.map((data, index) => (
          <div className="img-container" key={data.id}>
            <Items
              id={data.id}
              img={data.image}
              placeholder={data.imagePlaceholder}
              figcaption={data.title}
              alt={data.title}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExploreImage;
