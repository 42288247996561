
import React from 'react';

const WebDesignerTab = () => {
  return (
    <div id="tab2" className="tabcontents">

    <div className="row">
      <div className="col-lg-12">
        <h3 className="mt-5">Web Designer</h3>

        <div className="spacer1"></div>

        <h4> Open Position  : 2 Web designer</h4>

        <h5>Srishti is looking at 5 plus years experienced candidates who are strong at design of websites and
          web development.</h5>





      </div>

      <div className="col-lg-12">

        <h4> Core competencies we look at:</h4>
        
        <h5>Keen eye for design and skills to put together a great fantastic front end</h5><br/>
        <h5>Visualisation of site architecture and build it</h5><br/>
        <h5>Good hands on experience of softwares</h5><br/>
        <h5>knowledge of HTML5, CSS3, Bootstrap, Javascript, Angular JS and J Query</h5><br/>
        <h5>Build websites which are SEO friendly</h5><br/>
        <h5>Responsive website development - a mandate</h5><br/>
        <h5> Proficient in design, development of websites</h5><br/>
        <h5> Ability to work on short timelines</h5><br/>
        <h5>Keen eye to detail in pixel perfection design</h5><br/>
        <h5>Bring our new visual experiences in Web trend</h5><br/>

        {/* <ul>
          <li>Keen eye for design and skills to put together a great fantastic front end.</li>
          <li>Visualisation of site architecture and build it</li>
          <li>Good hands on experience of softwares</li>
          <li>knowledge of HTML5, CSS3, Bootstrap, Javascript, Angular JS and J Query</li>
          <li>Build websites which are SEO friendly</li>
          <li> Responsive website development - a mandate</li>
          <li> Proficient in design, development of websites.</li>
          <li>Urge to experiment new technologies</li>
          <li>Proactive in work and a team player</li>
          <li> Ability to work on short timelines</li>
          <li>Keen eye to detail in pixel perfection design</li>
          <li>Bring our new visual experiences in Web trend</li>


        </ul> */}

        <h5>We are looking for an organised, efficient and detail oriented team player with a will "To Do"
          attitude and exploring the discovery drive.</h5>

        <h5>Knowledge of managing Hosting, Domain management, Wordpress or any other CMS would be added
          advantages. Candidates with experience in CMS may also apply.</h5>

        <h4>Key Skills:</h4> <br/>
        <h5>Web technologies</h5><br/>
        <h5>Web Designing</h5> <br/>
        {/* <ul>
          <li>Web technologies</li>
          <li>Web Designing</li>
        </ul> */}
        <h4>Desired Candidate Profile</h4>
        <h5>Good at Communication - English, Hindi and one Regional language.</h5>
        <h5>Strong at grasping new technology trends and implement it.</h5>
        <h5>Team player</h5>
        <h5>Willingness to travel</h5>
        <h5>If work calls be able to manage the scenario with responsibility.</h5>
        <h5>Able to think creatively and adapt it in design.</h5>
        <h5>At Srishti we believe in Quality and Out of the Box ideas and technology solutions to be given to
          our customers.</h5>
        <h4>Remuneration:</h4> <br/>
        <h5>Negotiable depending on relevant experience and design test</h5>
      </div>
    </div>
  </div>
  );
};

export default WebDesignerTab;
