import { Link } from "react-router-dom";
import "../Home/Home.css";
import NotFoundImage from "../../Assets/images/not-found.gif";

const NotFoundPage = () => {
  return (
    <div className="NotFound py-4">
      <div className="not-found-container">
        <img
          src={NotFoundImage}
          alt="A person in a green shirt searching for something with a huge question mark right next to him"
          className="not-found-image"
        />

        <div className="d-flex flex-column align-items-center not-found-desc">
          <h2 className="mb-4">Lost? That's Ok!</h2>

          <p className="mb-5 text-center">
            Hey, we all lose our way sometimes <br />
            but the journey 🏡 is just a click away!
          </p>

          <Link to="/" className="d-inline-flex homepage-btn">
            Go to Homepage
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
