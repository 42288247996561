import React from "react";
import "./RecentWork.css";
import Items from "../Items/Items";
import imageData from "../../Assets/Datas/imageData";
import { Link } from "react-router-dom";

export const selectIds = [1, 14, 17, 21, 15, 4];
// const data_product = imageData.filter((item) => selectIds.includes(item.id));
const data_product = selectIds.map((id) =>
  imageData.find((data) => data.id === id)
);

const RecentWork = () => {
  return (
    <div className="RecentWork container-fluid">
      <div className="row">
        <div className="mb-4 d-flex justify-content-between align-items-center">
          <h3>Our Recent Works</h3>

          <Link
            to="/Work"
            className="arrow d-flex justify-content-center align-items-center"
          >
            <span className="rArrow"></span>
          </Link>
        </div>

        <div className="RecentWork--cards mt-4">
          {data_product.map((item) => (
            <div
              key={item.id}
              className="col-lg-4 col-md-4 col-md-12 rec-work w-100 h-100"
              data-aos="fade-up"
            >
              <Items
                key={item.id}
                id={item.id}
                img={item.image}
                placeholder={item.imagePlaceholder}
                figcaption={item.title}
                alt={item.image}
                data-aos="fade-up"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecentWork;
