import React from "react";

const GraphicDesignerTab = () => {
  return (
    <div id="tab4" className="tabcontents">
      <div className="row">
        <div className="col-lg-12">
          <h3 className="mt-5">Sr Visualiser / Sr Graphic Designer</h3>
          <div className="spacer1"></div>

          <h4>Open Position : 1 Sr Visualiser / Sr Graphic Designer</h4>

          <h5>
            We are looking for a talented Visualiser to join our team. As a
            Visualiser, you will be responsible for creating visual concepts to
            communicate ideas that inspire, inform, and captivate consumers. You
            will work closely with the creative team to develop designs that
            meet the project’s objectives.
          </h5>
        </div>

        <div className="col-lg-12">
          <h5>
            {" "}
            Should have a minimum of 4 years of experience in the advertising
            industry
          </h5>
          <h4>Key Duties:</h4>
          <h5>
            Collaborate with designers, copywriters, and other professionals to
            create visuals that convey a message in an effective manner.
          </h5>
          <br />
          <h5>
            Develop design briefs by gathering information and data through
            research.
          </h5>
          <br />
          <h5>
            Create visuals such as logos, illustrations, brochures,
            advertisements, and website designs
          </h5>
          <br />
          <h5>Use software to create designs</h5>
          <br />
          <h5> Amend designs after feedback</h5>
          <br />
          <h5>
            Ensure final graphics and layouts are visually appealing and
            on-brand
          </h5>
          <br />
          <h5>Prepare rough drafts and present ideas.</h5>
          <br />
          <h5>
            Develop illustrations, logos and other designs using software or by
            hand
          </h5>
          <br />
          <h5>Use the appropriate colors and layouts for each graphic</h5>
          <br />
          <h5>Proven experience as a visualiser or similar role</h5>
          <br />
          <h5>
            Knowledge of design software including Adobe Photoshop, Illustrator
            etc.
          </h5>
          {/* <ul>
  <li> Collaborate with designers, copywriters, and other professionals to create visuals that convey
    a message in an effective manner.</li>
  <li>Develop design briefs by gathering information and data through research.</li>
  <li> Create visuals such as logos, illustrations, brochures, advertisements, and website designs
  </li>
  <li>Use software to create designs</li>
  <li> Amend designs after feedback</li>
  <li> Ensure final graphics and layouts are visually appealing and on-brand</li>
  <li> Prepare rough drafts and present ideas.</li>
  <li>Develop illustrations, logos and other designs using software or by hand</li>
  <li>Use the appropriate colors and layouts for each graphic</li>
  <li> Proven experience as a visualiser or similar role</li>
  <li> Knowledge of design software including Adobe Photoshop, Illustrator etc.</li>



</ul> */}
          <h4>Desired Candidate Profile</h4> <br />
          <h5>Please refer to the Job description above</h5> <br />
          <h4>Remuneration:</h4> <br />
          <h5>Negotiable depending on relevant experience and design test</h5>
        </div>
      </div>
    </div>
  );
};

export default GraphicDesignerTab;
