import React from "react";

const PPTDesignerTab = () => {
  return (
    <div id="tab6" className="tabcontents">
      <div className="row">
        <div className="col-lg-12">
          <h3 className="mt-5">PPT Designer</h3>
          <div className="spacer1"></div>
          <h4> Open Position : 2 PPT Designer</h4>

          <h5>
            Relevant 4-5 years of experience working with product companies or
            consumer-facing applications.
          </h5>

          <h5>
            Convert current text heavy presentations to more concise,
            graphically rich presentations Interpret the data / content and
            present it visually with an aim to communicate the message
            effectively.
          </h5>
          <p>
            Presenting data more effectively using graphic elements such as
            charts, graphs, maps, tables and text.
          </p>
          <h5>
            Think creatively to produce new ideas and concepts for graphic
            presentation and concept design.
          </h5>
          <h5>
            Improve the overall look and feel of the presentations and documents
            while adhering to the corporate brand guidelines.
          </h5>
          <h5>
            Ability in meeting with deadlines and handling multiple projects
            independently.
          </h5>
          <h5>
            Relevant 2-4 years of experience working with product companies or
            consumer-facing applications.
          </h5>
        </div>

        <div className="col-lg-12">
          <h4>Key Duties:</h4> <br />
          {/* <ul> */}
          <h5>
            {" "}
            Expert knowledge of all facets of Microsoft Office (ie: PowerPoint).
          </h5>
          <h5> Solid knowledge of InDesign, Illustrator and Photoshop</h5>
          <h5>
            {" "}
            Ability to convey thoughts and ideas clearly and effectively in
            verbal and written format.
          </h5>
          <h5> Strong attention to detail and organizational skills.</h5>
          {/* </ul> */}
          <h4>Remuneration:</h4> <br />
          <h5>Negotiable depending on relevant experience and design test</h5>
        </div>
      </div>
    </div>
  );
};

export default PPTDesignerTab;
