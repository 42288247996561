import React, { useEffect } from "react";
import { preloadImages } from "../Utilities/preloadImages";

import Banner1xWebp from "../../Assets/images/Banner_1x.webp";
import Banner1xJpg from "../../Assets/images/Banner_1x.jpg";
import Banner2xWebp from "../../Assets/images/Banner_2x.webp";
import Banner2xJpg from "../../Assets/images/Banner_2x.jpg";
import Banner3xWebp from "../../Assets/images/Banner_3x.webp";
import Banner3xJpg from "../../Assets/images/Banner_3x.jpg";
import Banner4xWebp from "../../Assets/images/Banner_4x.webp";
import Banner4xJpg from "../../Assets/images/Banner_4x.jpg";

function Banner({ children }) {
  useEffect(() => {
    const preloadLinks = [
      Banner1xWebp,
      Banner1xJpg,
      Banner2xWebp,
      Banner2xJpg,
      Banner3xWebp,
      Banner3xJpg,
      Banner4xWebp,
      Banner4xJpg,
    ];

    preloadImages(preloadLinks);
  }, []);

  return <div className="Home container-fluid">{children}</div>;
}

export default Banner;
