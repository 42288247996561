import "./Home.css";
import SolutionsVideo from "./SolutionsVideo.js";
import OurService from "../OurService/OurService.jsx";
import RecentWork from "../RecentWork/RecentWork.js";
import Videonature from "../Videonature/Videonature";
import BrandsCarousel from "../Carosel/Carosel.js";
import { Link } from "react-router-dom";
import Banner from "./Banner.js";

// const Videonature = lazy(() => import("../Videonature/Videonature"));
// const Carousel = lazy(() => import("../Carosel/Carosel.js"));
// const RecentWork = lazy(() => import("../RecentWork/RecentWork.js"));
// const OurService = lazy(() => import("../OurService/OurService.jsx"));

const Home = () => {
  return (
    <section>
      <Banner>
        <div className="Home-vid">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <h2>Expertise</h2>
                <div className="container-fluid">
                  <div className="row exp-li">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 home-ul-li">
                      <ul>
                        <li>Brand Consultancy </li>
                        <li>Market Strategy </li>
                        <li>Brand Concepts </li>
                        <li>Visual Identity</li>
                        <li>Logotype & Mark</li>
                      </ul>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 home-ul-li2">
                      <ul>
                        <li>Visual Motion</li>
                        <li>Packaging</li>
                        <li>Creative Design </li>
                        <li>Brand Awareness </li>
                        <li>Website Development </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 typo">
                <h1>BRANDING CREATES</h1>
                <div className="container1__textslider--outer">
                  <div className="contai1">
                    CULTURE <br />
                    FUTURE <br />
                    TRUST <br />
                    CLIENTELE <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Banner>

      <div className="container-fluid" data-aos="fade-up">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="design-Srishti">
              <h4>
                Srishti is a leading design and digital agency that specializes
                in creating visually stunning and impactful designs. Our team of
                young, inquisitive minds believes in pushing boundaries,
                conducting thorough research, and delivering innovative
                solutions that make your brand stand out from the crowd.
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid" data-aos="fade-up">
        <div className="row">
          <SolutionsVideo />
        </div>
      </div>

      {/* <OurService data-aos="fade-up" />
      <RecentWork data-aos="fade-up" />
      <Videonature data-aos="fade-up" />
      <Carosel data-aos="fade-up" /> */}

      {/* <Suspense>
        <OurService data-aos="fade-up" />
        <RecentWork data-aos="fade-up" />
        <Videonature data-aos="fade-up" />
        <Carousel/>
      </Suspense> */}

      <OurService data-aos="fade-up" />
      <RecentWork data-aos="fade-up" />
      <Videonature data-aos="fade-up" />
      <BrandsCarousel />

      <div className="container-fluid mt-4 footer_bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
              <h3>Own What's Next with Srishti.</h3> <br />
              <h4>
                Interested in choosing Srishti as your creative and digital
                agency? Reach out to us about your goals and how we can help you
                realize them.
              </h4>
              <div className="mt-5 com-btn">
                <Link to="/Contact">
                  <button className="common_btn" name="btn-submit">
                    <span>Contact Us</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Home;
