import React from "react";
import { Carousel } from "react-bootstrap";
import "./Carosel.css";
import Image1 from "../../Assets/images/client_01.svg";
import Image2 from "../../Assets/images/client_02.svg";
import Image3 from "../../Assets/images/client_03.svg";

const BrandsCarousel = () => {
  return (
    <>
      <div className="container-fluid" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-8 col-row-8 col-sm-8"></div>
          <h3 className="me-th">
            Meet the <strong>brands</strong> partnering with Srishti.
          </h3>
        </div>
      </div>

      <Carousel className="d-block d-xl-block">
        <Carousel.Item>
          <img
            className="d-block w-100 image-page"
            src={Image1}
            alt="First slide"
          />
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100 image-page"
            src={Image2}
            alt="Second slide"
          />
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100 image-page"
            src={Image3}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default BrandsCarousel;
