import React, { useContext } from "react";
import "./OurService.css";
import Branding from "../ourBranding/Branding";
import { ServiceContext } from "../Context/ServiceContext";

const OurService = () => {
  const { ContentService } = useContext(ServiceContext);

  const firstSixContent = ContentService.slice(0, 6);
  return (
    <div className="container-fluid mt-4 h3-ou">
      <h3 className="mb-4">Services</h3>
      <div className="row btnn2">
        {firstSixContent.map((item) => (
          <div key={item.id} className="col-lg-4 col-md-4 sol-sm-12">
            <Branding
              name={item.name}
              description={item.description}
              link={item.link}
              key={item.id}
              id={item.id}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurService;
