import React from "react";
// import AutoChangeContent from "../../Assets/Datas/Autochangecontent.js";
import { aboutContent } from "../../Assets/Datas/ContentData";
// import contentData from "../../Assets/Datas/ContentData";
import "./About.css";
// import { Image } from "react-bootstrap";
import VideoHome from "../Pages/VideoHome/VideoHome.jsx";
import Image1 from "../../Assets/images/Hi.gif";
import Image2 from "../../Assets/images/srishti.webp";
import Image3 from "../../Assets/images/srishti_office.webp";
const About = () => {
  // const [displayedText, setDisplayedText]=useState('');

  // useEffect(()=>{
  //   const passionText = 'PASSION';

  //   passionText.split('').forEach((letter,index)=>{
  //     setTimeout(()=>{
  //       setDisplayedText((prevText)=>prevText + letter);
  //     }, index*300);
  //   })
  // },[]);

  return (
    <>
      <div className="section about-section">
        <div className="container-fluid about" data-aos="fade-up">
          <div className="row about-page">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h1>{aboutContent.heading}</h1>
            </div>
          </div>
        </div>

        <div className="aboutimage">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 hii-image-gif">
                <img src={Image1} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <VideoHome />
      <div className="passion-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="intro">
                <h4>
                  Discover Srishti, the leading premium creative design studio
                  in Bengaluru, with a legacy of serving customers for close to
                  14 years across diverse industries.
                  <br /> <br />
                  With our extensive experience across various domains and
                  industry verticals like education, automobile, banking,
                  manufacturing, food and beverages, healthcare and more, we
                  understand the unique challenges and requirements of each
                  industry. Our team of experts combine creativity and strategy
                  to provide innovative solutions tailored specifically to meet
                  your business objectives
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-s-12 p-0">
              <img
                src={Image2}
                alt="img"
                style={{ width: "100%", height: "auto" }}
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="about-story-whole">
        <div className="container-fluid mt-5">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12 about-story">
              <h3>Our Story</h3>
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="ab-ou-t">
                <h4>
                  At Srishti, we understand the importance of a strong brand
                  identity. Our experts work closely with you to develop
                  tailored branding strategies that reflect your unique vision
                  and goals. From logo design to comprehensive brand guidelines,
                  we ensure your brand leaves a lasting impression.{" "}
                </h4>
                <h4>
                  Your success is our priority We are committed to delivering
                  excellence in every project we undertake. Our customer-centric
                  approach ensures that we work closely with you throughout the
                  process, providing regular updates and incorporating your
                  feedback to ensure the final product exceeds your
                  expectations.{" "}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <section className="our-clients mt-5 ">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-md-12 col-sm-12 client ">
                <h3>Our Clients</h3>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-4 client_list">
                ABB <br />
                Aron Universal <br />
                BlueBinaries <br />
                Continental <br />
                Centum Adeneo <br />
                Centum Electronics <br />
                Coffee Board of India <br />
                Diebold <br />
                Eigen <br />
                Enarka <br />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-4 client_list">
                FiberXellence <br />
                herbartizan <br />
                Icchaa Fertility <br />
                Kali Tiger Reserve <br />
                KSB <br />
                LGM Engineering <br />
                Natural Remedies <br />
                Navya Foods <br />
                Netrack <br />
                Ohmium <br />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12 client_list">
                QDC <br />
                Rakon <br />
                Sapphire Dermatology <br />
                Seetha Ice Creams <br />
                Sienna ECad <br />
                STPI <br />
                Suprajit Engineering Ltd <br />
                The Golden Scoop <br />
                Toyota Kirloskar Motors <br />
                Triveni Turbines <br />
              </div>
            </div>
          </div>
        </section>
        <div className="container-fluid mt-5">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12 about-story">
              <h3>Our Studio</h3>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                  <h3 className="">
                    A CALM SPACE FOR DESIGNERS TO INCREASE THEIR CREATIVITY AND
                    HAPPINESS
                  </h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4"></div>
              <div className="col-lg-8 mt-4">
                <img
                  src={Image3}
                  alt="img"
                  style={{ width: "100%", height: "auto" }}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
