import { Routes, Route } from "react-router-dom";

import "./App.css";
import Navv from "./Components/Navv/Navv";
import Home from "./Components/Home/Home";
import About from "./Components/About/About";
import AOS from "aos";
import "aos/dist/aos.css";
import Work from "./Components/Work/Work";
import Contact from "./Components/Contact/Contact";
import Fotter from "./Components/Fotter/Fotter";
import DetailsPage from "./Components/Pages/DetailsPage/DetailsPage";
import DesignService from "./Components/Pages/DesignService/DesignService";
import ServiceContextProvider from "./Components/Context/ServiceContext";
import Service from "./Components/Service/Service";
import Career from "./Components/Career/Career";
import WorkContextProvider from "./Components/Context/WorkContext";
import { useEffect } from "react";
import { PathName } from "./Components/Utilities/getPathName";
import ThankYouPage from "./Components/Form/ThankYouPage";

import NotFoundPage from "./Components/Pages/NotFoundPage";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
    AOS.refresh();
  });

  const pathName = PathName();
  const appClass = pathName ? pathName + "-page" : "home-page";

  return (
    <div className={`App ${appClass}`}>
      <ServiceContextProvider>
        <WorkContextProvider>
          <Navv />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path="/Service" element={<Service />} />
            <Route path="/Work" element={<Work />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Career" element={<Career />} />
            <Route path="/details/:id" element={<DetailsPage />} />
            <Route path="/branding/:id" element={<DesignService />} />
            <Route path="/ThankYou" element={<ThankYouPage />} />

            <Route path="*" element={<NotFoundPage />} />
          </Routes>

          <Fotter />
        </WorkContextProvider>
      </ServiceContextProvider>
    </div>
  );
}

export default App;
