import React from "react";
import iconicSolutionsWebm from "../../Assets/images/IconsSolution.webm";
import iconicSolutionsMp4 from "../../Assets/images/IconsSolution.mp4";

function SolutionsVideo() {
  return (
    <div className="col-lg-12 col-sm-12 col-md-12 image-solutio">
      <video autoPlay loop muted width="100%">
        <source src={iconicSolutionsWebm} type="video/webm"></source>
        <source src={iconicSolutionsMp4} type="video/mp4"></source>
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default SolutionsVideo;
