import React, { useState } from "react";
import imageData from "../../Assets/Datas/imageData";
import Items from "../Items/Items";
import "./Work.css";
import "../RecentWork/RecentWork.css";

const Work = () => {
  const [selectedCategory, setSelectedCategory] = useState("ALL");

  const handleClick = (category) => {
    setSelectedCategory(category);
  };

  const filterData =
    selectedCategory === "ALL"
      ? imageData
      : imageData.filter((item) => item.category === selectedCategory);

  return (
    <div className="container-fluid work">
      <div className="work-button d-none">
        <button
          onClick={() => handleClick("ALL")}
          className={selectedCategory === "ALL" ? "selected" : ""}
        >
          ALL
        </button>
        <button
          onClick={() => handleClick("BRANDING")}
          className={selectedCategory === "BRANDING" ? "selected" : ""}
        >
          BRANDING
        </button>
        <button
          onClick={() => handleClick("UI/UX")}
          className={selectedCategory === "UI/UX" ? "selected" : ""}
        >
          UI / UX
        </button>
        <button
          onClick={() => handleClick("PRESENTATION")}
          className={selectedCategory === "PRESENTATION" ? "selected" : ""}
        >
          PRESENTATION
        </button>
        <button
          onClick={() => handleClick("VIDEOS")}
          className={selectedCategory === "VIDEOS" ? "selected" : ""}
        >
          VIDEOS
        </button>
      </div>

      <div className="RecentWork--cards mt-2">
        {filterData
          .filter(
            (item) =>
              selectedCategory === "ALL" || item.category === selectedCategory
          )
          .map((item) => (
            <div
              key={item.id}
              className="col-xxl-3 col-lg-4 col-md-4 col-sm-12 w-100 h-100"
            >
              <Items
                key={item.id}
                id={item.id}
                img={item.image}
                placeholder={item.imagePlaceholder}
                figcaption={item.title}
                alt={item.image}
              />{" "}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Work;

// import React, { useState } from 'react';
// import imageData from '../../Assets/Datas/imageData';
// import Items from '../Items/Items';
// import './Work.css';

// const Work = () => {
// const [selectedCategory, setSelectedCategory] = useState('ALL');

//   const handleClick = (category) => {
//     setSelectedCategory(category);
//   };

//   const filteredData = selectedCategory === 'ALL'
//     ? imageData
//     : imageData.filter(item => item.category === selectedCategory);

//   return (
//     <div className='container work'>
//       <div className='work-button'>
//         <button onClick={() => handleClick('ALL')}>ALL</button>
//         <button onClick={() => handleClick('BRANDING')}>BRANDING</button>
//         <button onClick={() => handleClick('UI/UX')}>UI / UX</button>
//         <button onClick={() => handleClick('PRESENTATION')}>PRESENTATION</button>
//         <button onClick={() => handleClick('VIDEOS')}>VIDEOS</button>
//       </div>
//       <div className='row'>
//         {filteredData.map((item) => (
//           <div key={item.id} className='col-lg-4 col-md-4 col-md-12'>
//             <Items key={item.id} id={item.id} img={item.image} figcaption={item.title} alt={item.image} />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default Work;
