import downloadIcon from "../../Assets/images/download.png";
import "./Form.css";

const ThankYouPage = () => {
  const headerStyle = {
    marginTop: "20px",
    marginBottom: "30px",
    fontWeight: "600 !important",
  };

  return (
    <div
      className="container-fluid contact-section aos-animate aos-p thank-you-page"
      data-aos="fade"
    >
      <div className="row">
        <div className="col-lg-2"></div>
        <div className="col-lg-9 col-md-12 col-sm-12 enq">
          <h1 style={headerStyle}>Thank You!</h1>

          <h3>We're working on your request – stay close!</h3>

          <h3>
            Ready, set, download – it's all about the profile!
            <a
              href="https://srishtiindia.com/srishti.pdf"
              download
              target="_blank"
              rel="noreferrer"
              className="d-inline-flex"
            >
              <img
                src={downloadIcon}
                alt="download profile"
                width={75}
                height={75}
              />
            </a>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
