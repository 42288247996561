import React from "react";
import Videonaturee from "../../Assets/images/for_web.webm";
import "./Videonature.css";

const Videonature = () => {
  return (
    <section>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 vid-nature">
            <video src={Videonaturee} preload muted loop poster autoPlay>
              Your browser does not support the video tag.
            </video>
            <div className="text-box text-boxx"> INSPIRED BY NATURE </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Videonature;
