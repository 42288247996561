import React from "react";
import "./VideoHome.css";
import VideoMp4 from "../../../Assets/images/home-video.mp4";
import VideoWebm from "../../../Assets/images/home-video.webm";

const VideoHome = () => {
  return (
    <div className="container-fluid home-vid d-flex">
      <video preload muted loop poster autoPlay>
        <source src={VideoWebm} type="video/webm" />
        <source src={VideoMp4} type="video/mp4" />
        Sorry, your browser doesn't support videos.
      </video>
    </div>
  );
};

export default VideoHome;
