import abbImage from "../images/abb.webp";
import abbImagePlaceholder from "../images/abb_small.webp";
import BannerAbb from "../images/abb_banner.webp";
import ImageAbb1 from "../images/abbwall.webp";
import ImageAbb2 from "../images/abbwall4.webp";
import ImageAbb3 from "../images/abbwall3.webp";
import ImageAbb4 from "../images/broucher_abb.webp";
import ImageAbb5 from "../images/abbstationaray.webp";
import ImageAbb6 from "../images/abbstationaray1.webp";
// import ImageAbb7 from "../images/abbwall.webp";

import agsamImage from "../images/agsam.webp";
import agsamImagePlaceholder from "../images/agsam_small.webp";
import BannerAgsam from "../images/ag5agsam.gif";
import ImageAgsam1 from "../images/ag3Agsam.webp";
import ImageAgsam2 from "../images/ag4Agsam.webp";
import ImageAgsam3 from "../images/agsam1.webp";

import aronImage from "../images/aron.webp";
import aronImagePlaceholder from "../images/aron_small.webp";
import BannerAron from "../images/AronBanner.webp";
import ImageAron1 from "../images/Aroncertificate.webp";
import ImageAron2 from "../images/aroncorporate_panel.jpg";
import ImageAron3 from "../images/Aronstall.webp";

import blueImage from "../images/blue_binaries.webp";
import blueImagePlaceholder from "../images/blue_binaries_small.webp";
import Bannerblue from "../images/blue_binaries_banner.webp";
import Imagebb1 from "../images/bluebinaries1.webp";
import Imagebb2 from "../images/bluebinaries2.webp";
import Imagebb3 from "../images/bluebinaries3.webp";
//  import Imagebb4 from "../images/bb.webp";

import centumImage from "../images/centum.webp";
import centumImagePlaceholder from "../images/centum_small.webp";
import BannerCentum from "../images/centum_video.webm";
import ImageCentum1 from "../images/Centumtabletmockup.webp";
import ImageCentum2 from "../images/centumbroucher.webp";
import ImageCentum3 from "../images/annual_report.webp";
import ImageCentum4 from "../images/Centum-Mock-2.webp";

import coffeImage from "../images/coffee.webp";
import coffeeImagePlaceholder from "../images/coffee_small.webp";
import Bannercoffee from "../images/coffee_banner.webp";
import ImageCoffee1 from "../images/coffee_ppt.webp";
import ImageCoffee2 from "../images/coffee2.webp";
import ImageCoffee3 from "../images/coffee4.webp";
import ImageCoffee4 from "../images/CoffeeBag.webp";
import ImageCoffee5 from "../images/Coffeeshirtsmockup.webp";

import continentalImage from "../images/continental.webp";
import continentalImagePlaceholder from "../images/continental_small.webp";
import Bannercontinental from "../images/continental_branding.webp";
import Imagecontinental1 from "../images/continentalcertificate.webp";
import Imagecontinental2 from "../images/continentaldairy.webp";
import Imagecontinental3 from "../images/continentalstandee.webp";
import Imagecontinental4 from "../images/continentalstationary.webp";

import dairy_richImage from "../images/dairy_rich.webp";
import dairy_richImagePlaceholder from "../images/dairy_rich_small.webp";
import BannerDiary from "../images/dairy-rich_banner.webp";
import Imagedairy_rich1 from "../images/dairy_richshop.webp";
import Imagedairy_rich2 from "../images/dairy_richcup.webp";
import Imagedairy_rich3 from "../images/dairy_richNuttybarMockup.webp";
import Imagedairy_rich4 from "../images/dairy_richroseberry.webp";
import Imagedairy_rich5 from "../images/dairyrich_mockup.webp";

import eigenImage from "../images/eigen.webp";
import eigenImagePlaceholder from "../images/eigen_small.webp";
import BannerEigen from "../images/Eigen_banner.webp";
import Imageeigen1 from "../images/eigen_historywall.webp";
import Imageeigen2 from "../images/eigen1.webp";
import Imageeigen3 from "../images/eigen2.webp";
import Imageeigen4 from "../images/eigen3.webp";

import fiberexcellenceImage from "../images/Fiberxcellence.webp";
import fiberexcellenceImagePlaceholder from "../images/Fiberxcellence_small.webp";
import Bannerfiberexcellence from "../images/fiberxcellence_banner.webp";
import Imagefiverexcellance1 from "../images/fibervisiting.webp";
import Imagefiverexcellance2 from "../images/fiberweb.webp";
import Imagefiverexcellance3 from "../images/fibervisiting.webp";
import Imagefiverexcellance4 from "../images/fiberxcellence1.webp";

import golden_scoopImage from "../images/golden_scoop.webp";
import golden_scoopImagePlaceholder from "../images/golden_scoop_small.webp";
import BannerGolden from "../images/golden-scoop_banner.webp";
import ImageGoldenscope1 from "../images/goldenTable.webp";
import ImageGoldenscope2 from "../images/golden_burger_pack.webp";
import ImageGoldenscope3 from "../images/golden_cone.webp";
import ImageGoldenscope4 from "../images/goldenscoop.webp";

import herbartizanImage from "../images/herbartizan.webp";
import herbartizanImagePlaceholder from "../images/herbartizan_small.webp";
import BannerHerbaertizan from "../images/herbartizan_banner.webp";
import ImageHerb1 from "../images/Herb.webp";
import ImageHerb2 from "../images/Herbartiz.webp";
import ImageHerb3 from "../images/herbartizan_broucher.webp";
// import ImageHerb4 from "../images/herbartizan_package.webp";
// import ImageHerb5 from "../images/herbartizan_packages.webp";

import indodentImage from "../images/indodent.webp";
import indodentImagePlaceholder from "../images/indodent-new_small.webp";
import Bannerindodent from "../images/indodent_box.webp";
import Imageindodent1 from "../images/indodent_brush.webp";
import Imageindodent2 from "../images/indodnt_brush_indodent.webp";
import Imageindodent3 from "../images/indodent_box.webp";

import krideImage from "../images/kride.webp";
import krideImagePlaceholder from "../images/kride_small.webp";
import BannerKride from "../images/k-ride_banner.webp";
import ImageKride1 from "../images/kride-invitation.webp";
import ImageKride2 from "../images/kridegraphic.webp";
import ImageKride3 from "../images/kridewall_graphic_.webp";
import ImageKride4 from "../images/kridegraphic_board.webp";
import ImageKride5 from "../images/kridedigital_board.webp";

import ktrImage from "../images/ktr-new.webp";
import ktrImagePlaceholder from "../images/ktr-new_small.webp";
import BannerKtr from "../images/ktrBanner.webp";
import Imagekalitiger1 from "../images/ktr_web.webp";
import Imagekalitiger2 from "../images/kalitiger_bags.webp";
import Imagekalitiger3 from "../images/kalitiger_diary.webp";
import Imagekalitiger4 from "../images/kalitiger_frames.webp";

import logsafeImage from "../images/logsafe.webp";
import logsafeImagePlaceholder from "../images/logsafe_small.webp";
import Bannerlogosafe from "../images/logsafe_banner.webp";
import Imagelogosafe1 from "../images/logosafe-app.webp";
import Imagelogosafe2 from "../images/logosafe-stationery.webp";
import Imagelogosafe3 from "../images/logosafe-cover-2.webp";
import Imagelogosafe4 from "../images/logosafe-appmock.webp";
import Imagelogosafe5 from "../images/logosafe-cover-1.webp";
import Imagelogosafe6 from "../images/logosafe-img-h.webp";

import netrackImage from "../images/netrack.webp";
import netrackImagePlaceholder from "../images/netrack_small.webp";
import BannerNetrack from "../images/Netrack_banner.webp";
import ImageNetrack1 from "../images/Netrack-Corporate-bag.webp";
import ImageNetrack2 from "../images/Netrack-Outdoor-boarding.webp";
import ImageNetrack3 from "../images/Netrack-Outdoor-Standee.webp";
import ImageNetrack4 from "../images/Netrackbrochure.webp";
import ImageNetrack5 from "../images/NetrackCertificate.webp";

import ohmiumImage from "../images/ohmium.webp";
import ohmiumImagePlaceholder from "../images/ohmium_small.webp";
import Bannerohmium from "../images/ohmiumBanner.webp";
import Imageohmium1 from "../images/ohmium_brochure.webp";
import Imageohmium2 from "../images/ohmium_cards.webp";
import Imageohmium3 from "../images/ohmium_backdrop.webp";
import Imageohmium4 from "../images/ohmium_cup.webp";
import Imageohmium5 from "../images/ohmium_banner.webp";
import Imageohmium6 from "../images/ohmium-visitingcard.webp";
import Imageohmium7 from "../images/ohmium_roll_ups_.webp";

import toyotaImage from "../images/toyota.webp";
import toyotaImagePlaceholder from "../images/toyota_small.webp";
import Bannertoyota from "../images/tshield.mp4";
import Imagetoyota1 from "../images/toyotabrochure.webp";
import Imagetoyota2 from "../images/toyotaflipbook_digital.webp";
import Imagetoyota3 from "../images/toyotaflipbook2.webp";
import Imagetoyota4 from "../images/toyota-gif.webp";
import Imagetoyota5 from "../images/toyota_glanza.webp";

import triveniImage from "../images/triveni.webp";
import triveniImagePlaceholder from "../images/triveni_small.webp";
import Bannertriveni from "../images/triveni_banner.webp";
import Imagetriveni1 from "../images/Triveni-Calender.jpg";
import Imagetriveni2 from "../images/triveni_dairy2.webp";
import Imagetriveni3 from "../images/Triveni_Brochure.webp";
import Imagetriveni4 from "../images/triveni_turbines.png";
import Imagetriveni5 from "../images/triveni_digital _bs card.webp";

import Imagetriveni7 from "../images/triveni_dairy1.webp";
import Imagetriveni8 from "../images/triveniddairy.webp";

import zepcoImage from "../images/zepco.webp";
import zepcoImagePlaceholder from "../images/zepco_small.webp";
import BannerZepco from "../images/Zepco_banner.webp";
import ImageZepco1 from "../images/zepco_visiting.webp";
import ImageZepco2 from "../images/Zepcodrone.webp";
import ImageZepco3 from "../images/zepcodroneimage.webp";
import ImageZepco4 from "../images/ZepkoVCMockup.webp";

export const imageData = [
  {
    id: 1,
    image: abbImage,
    imagePlaceholder: abbImagePlaceholder,
    title: "ABB",
    Paragraph:
      "company ​that energizes the transformation of society and ​industry",
    category: "Branding",
    Banner: BannerAbb,
    TheChallenge:
      "Provide new design for the Distributor Booklet and Third Party Agreement",
    TheChallenge2: "Distributor Booklet required a change in conceptualisation",
    TheChallenge3:
      "New Certiﬁcate Design for Distributors which needed to be at par with ABB Brand Guidelines",
    TheChallenge4:
      "Environmental Design / Concept for their Office Spaces that would enhance the brand essence",
    TheChallenge5:
      "COVID time Creatives were to be printed and supplied with challenges of movement in the city",
    TheChallenge6:
      "Branding consistency to be adhered in all regional languages for creatives",
    TheChallenge7: "3D visualisation of a Product then convert to Video ",
    TheChallenge8: "Concept for eMart Logo and Portal launch promotional video",
    TheChallenge9:
      "Warehouse branding for easy identification of shelves and racks",
    TheChallenge10:
      "Provide design concepts for NewsLetter and make them compatible for email circulation",
    TheChallenge12:
      "Design for Diversity wall – hand painted creatives on Diversity",
    TheChallenge13: "Concepts for Office Branding based on Business verticals",
    ourrole1: "Logo Design",
    ourrole2: "Brochure design",
    ourrole3: "Office Branding",
    ourrole4: "Warehouse Branding",
    ourrole5: "Environmental Graphics",
    ourrole6: "3D Product Design",
    ourrole7: "Animated Video production",
    ourrole8: "Printing solutions",
    ourrole9: "Video Developments",
    ourrole10: "Product Photoshoot",
    ourrole11: "Employee Photography",
    ourrole12: "GDN Banner designs",
    ourrole13: "Merchandise",
    ourrole14: "Supply of Corporate gifting",
    ourrole15: "Installation of Signages over Buildings",
    ourrole16: "Graphic creation, printing and installation at Offices",
    task1:
      "ABB is a leading global engineering company that energizes the transformation of society and industry to achieve a more productive, sustainable future. By connecting software to its electrification, robotics, automation and motion portfolio, ABB pushes the boundaries of technology to drive performance to new levels.",
    img4: ImageAbb4,
    img1: ImageAbb1,
    img2: ImageAbb2,
    img3: ImageAbb3,
    img5: ImageAbb5,
    img6: ImageAbb6,
    // img1:ImageAbb1,
    // img1:ImageAbb1,
  },
  {
    id: 2,
    image: zepcoImage,
    imagePlaceholder: zepcoImagePlaceholder,
    title: "Zepco",
    Banner: BannerZepco,
    Paragraph:
      "Zepco is set up in India’s startup hub Bengaluru. Zepco Technologies is a core power electronics start-up company that was founded by a team of engineers",
    TheChallenge:
      "Zepco is in a new gen company focussed on products that are innovative. ​",
    TheChallenge2: "Needed to build Brand Identity",
    TheChallenge3:
      "The company focussed on a business model that would help transform the drives industry",
    TheChallenge4:
      "As a startup the company looked for options that would help them get a stronger visibility ",
    TheChallenge5: "Design website and presentation decks",
    TheChallenge6:
      "Product packaging and other marketing collaterals are in the next",
    ourrole1: "Logo design ",
    ourrole2: "Website design",
    ourrole3: "Product Packaging",
    ourrole4: "Printing Solutions ",
    ourrole15: " Signages for Office ",
    task1:
      "Zepco is set up in India’s startup hub Bengaluru. Zepco Technologies is a core power electronics start-up company that was founded by a team of engineers, product builders, and marketers with a collective experience 150+ years in the space that they operate in. Their focus is to build various subsystems like motors, motor drives, AC/DC and DC/DC power supplies for drones primarily used in the defence industry; EV subsystems kits like motor, motor drives, and onboard chargers.",
    img1: ImageZepco1,
    img3: ImageZepco3,
    img4: ImageZepco4,
    img2: ImageZepco2,

    category: "VIDEOS",
  },
  {
    id: 3,

    image: krideImage,
    imagePlaceholder: krideImagePlaceholder,
    title: "Kride",
    Banner: BannerKride,
    Paragraph:
      "The SubUrban Railway which would be the next milestone that Bengaluru sees for easing commuting in the city.",
    TheChallenge:
      "K Ride had to showcase its plan on the suburban railway for the foundation stone ceremony by the Prime Minister of India",
    TheChallenge2:
      "Timeline was short yet we were asked to develop creatives and suggest ideas for the Pavilion that would be visited by the PM.",
    TheChallenge3:
      "This project had challenges to be met as the security policies were too strict and tampered our flow of wor",
    TheChallenge4:
      "Entire project was ideated and completed in a span of 12 days which included site visit, planning, design, print fabrication and handing over.",
    TheChallenge5:
      "We had to deliver an output that is a class in its own within the stipulated timeline.",
    ourrole1: "Design Concept",
    ourrole2: "Logo design for Siri Samparka",
    ourrole3: "Creatives for the panels",
    ourrole4: "Construction of Pavilion",
    ourrole5: "Environmental graphics",
    ourrole6: "Event backdrop",
    ourrole7: "Standee",
    ourrole8: "Product video",
    ourrole9: "Corporate Presentations",
    ourrole10: "Signages and Arches",
    ourrole11: "Video development",
    task1:
      "This is a State and Ministry of Railways combined project for Bengaluru – The SubUrban Railway which would be the next milestone that Bengaluru sees for easing commuting in the city.",
    task2:
      "We were approached by the organization for developing pavilion for a major event where the Prime Minister of India was to lay the foundation stone for the project..",

    img1: ImageKride1,
    img2: ImageKride2,
    img3: ImageKride3,
    img4: ImageKride4,
    img5: ImageKride5,
    category: "Branding",
  },
  {
    id: 4,
    image: coffeImage,
    title: "Coffee Board",
    imagePlaceholder: coffeeImagePlaceholder,
    Banner: Bannercoffee,
    Paragraph:
      "It is one of the premier organization that guides, helps and assists coffee growers in India in various forms.",
    TheChallenge:
      "Coffee Board as one of the oldest organisation lacked a brand consistency.​",
    TheChallenge2:
      "Communications in print / digital were not in sync and each one appeared different.",
    TheChallenge3:
      "Product packaging was done using silver pouch with a sticker.",
    TheChallenge4:
      "Each officer had his expertise that was expected in the design.​",
    TheChallenge5:
      "Getting the team together to a same platform of thinking is a major challenge as each one has a different opinion and are based on their experiences.",
    TheChallenge6:
      "Team had to consider all the views and deliver a design that meets their requirement.",
    TheChallenge7:
      "Develop a Video for the Atal Incubation Centre without a script or a storyboard.​",
    TheChallenge8:
      "Provide concept, design and installation for their promotion activity at KTPC.",
    TheChallenge9: "Concept for Product packaging for GI Coffees.",
    ourrole: "OUR ROLE COFFEE BOARD",
    ourrole1: "Developing of Brochure",
    ourrole2: "Product Packaging",
    ourrole3: "Gift Box packaging",
    ourrole4: "GI Coffee Product Packaging",
    ourrole5: "Printing and Supply of Packaging",
    ourrole6: "Product Photography",
    ourrole7: "Branding and design assistance",
    ourrole8: "PowerPoint Presentation",
    ourrole9: "AIC video Production",
    ourrole10: "Assistance in launching product on Amazon",
    task1:
      "Coffee Board of India is one of the premier organization that guides, helps and assists coffee growers in India in various forms. This organization dates back as one of the oldest in the country as does a significant contribution towards getting India on a global platform. As a central govt organization there were challenges in the upgradation of the coffee packaging and also suggest new sorts of opportunities that the coffee sector would demand.",
    task2:
      "We were approached for a plethora of services, and it gave us an opportunity to prove our nice.",
    img1: ImageCoffee1,
    img2: ImageCoffee2,
    img3: ImageCoffee3,
    img4: ImageCoffee4,
    img5: ImageCoffee5,
    category: "BRANDING",
  },

  {
    id: 5,
    image: dairy_richImage,
    imagePlaceholder: dairy_richImagePlaceholder,
    title: "Dairy rich",
    Banner: BannerDiary,
    Paragraph:
      "A brand known to few of them with their presence in Bangalore and a few regions in North Karnataka.",
    TheChallenge: "To re-brand",
    TheChallenge2: "To redesign product packaging",
    TheChallenge3:
      "To develop brand packaging, advertising strategy, branding of freezers and stores",
    TheChallenge4: "To build a web presence​",
    TheChallenge5: "To build brand recognition in competitive world",
    TheChallenge6: "To boost company revenue",
    ourrole1: "Complete branding & marketing strategy",
    ourrole2: "Logo design",
    ourrole3: "Stationery design",
    ourrole4: "Product packaging",
    ourrole5: "Menu board",
    ourrole6: "Shop signages",
    ourrole7: "Internal signages",
    ourrole8: "Magazine advertisement",
    ourrole10: "Store design",
    ourrole11: "Website",
    task1:
      "Dairy Rich Ice Creams was a brand known to few of them with their presence in Bangalore and a few regions in North Karnataka. There was a need to expand the business and hence we suggested a complete brand make over. The director of the company accepted our viewpoints.",
    img2: Imagedairy_rich2,
    img4: Imagedairy_rich4,
    img3: Imagedairy_rich3,
    img1: Imagedairy_rich1,

    img5: Imagedairy_rich5,

    category: "BRANDING",
  },

  {
    id: 6,
    image: blueImage,
    title: "Blue Binaries",
    imagePlaceholder: blueImagePlaceholder,
    Banner: Bannerblue,
    Paragraph: "UK based company specializing in automotive technologies",
    TheChallenge: "Concept thinking for the automotive technologies globally​",
    TheChallenge2: "Demand to make the Office a motivational place to work",
    TheChallenge3:
      "Testing and other areas to be branded well so that it creates a WOW feel among the visitors / customers",
    TheChallenge4: "Ideations had to be new and unique​",
    TheChallenge5: "Facilities to brand – Chennai and Pune​",
    TheChallenge6: "New concepts that are fresh and original in nature. ​",
    ourrole1: "Concept creation ",
    ourrole2: "Complete branding of office space",
    ourrole3: "Signages",
    ourrole4: "Internal Signages",
    img1: Imagebb1,
    img2: Imagebb2,
    img3: Imagebb3,
    task1:
      "Blue Binaries is a UK based company specializing in automotive technologies. We were approached by them to brand their new office spaces they opened for operations in India. ",
    task2:
      "We were asked to identify how we can adapt to their line of business and deliver concepts that drive the management and the staff through motivation and inspiration as they enter workspace. ",
    task3:
      "We at Srishti had to come up with innovative thought processes referring many international design standards and then ensure that the workspace meet the thoughts we proposed.",
    category: "BRANDING",
  },

  {
    id: 7,
    image: eigenImage,
    title: "Eigen",
    imagePlaceholder: eigenImagePlaceholder,
    Banner: BannerEigen,
    Paragraph: "The largest companies in the metal stamping domain",
    TheChallenge: "To build a strong brand recognition",
    TheChallenge2: "To postion Netrack as premium company",
    TheChallenge3:
      "To develop brand identity that would merge company with strong global presence",
    TheChallenge4: "To increase company growth​",

    ourrole: "Our Role Eigen",
    ourrole1: "Stationery design",
    ourrole2: "Brochure",
    ourrole3: "Sinages",
    ourrole4: "Event backdrop",
    ourrole5: "Standee",
    ourrole6: "Product video",
    ourrole7: "Corporate Presentations",
    task1:
      "A company that is a part of KDDL group stands for its relentlous contribution producing components to the Watch, Aerospace and a few electronics manufacturers.",
    task2:
      "They are one of the largest companies in the metal stamping domain. Eigen has a long-standing history of contributing to the Make In India policy.",
    img2: Imageeigen2,
    img1: Imageeigen1,

    img3: Imageeigen3,
    img4: Imageeigen4,
    category: "BRANDING",
  },

  {
    id: 8,
    image: ktrImage,
    title: "KTR",
    imagePlaceholder: ktrImagePlaceholder,
    Banner: BannerKtr,
    Paragraph: "The true essence of nature’s beauty.",
    TheChallenge: "​ Rebranding",
    TheChallenge2: "To develop Sinage, advertising and informative posters​",
    TheChallenge3: "To convey social message to preserve wildlife​",
    TheChallenge4: "To boost company revenue",
    TheChallenge5: "To establish a strong interpretation center​",
    TheChallenge6:
      "To motivate people to contribute in social cause to prevent the environment​",
    ourrole1: "Stationery design",
    ourrole2: "Complete branding of office space",
    ourrole3: "Product packaging",
    ourrole4: "Internal Signages",
    ourrole5: "Road side signages for the entire Reserve",
    ourrole6: "Production of Videos",
    ourrole7: "Filming of various flora and fauna",
    ourrole8: "Website with payment gateway",
    ourrole9: "Designing 3D sculpture of Hornbills",
    task1:
      "Rebranding: Logo that was the face of KTR earlier was more of an image. When we looked at this graphic we suggested that a Logo needs to be of a certain standard for which the Govt dept accepted to get it changed. This got us an opportunity to design a well thought logo",
    task2:
      "The Result: The logo was designed keeping in mind of the core functionality of the dept..Kali Tiger Reserve had to have a Tiger and Hornbill along with the River Kali which is the lifeline for the 2 endangered species.",
    img1: Imagekalitiger1,
    img2: Imagekalitiger2,
    img3: Imagekalitiger3,
    img4: Imagekalitiger4,
    category: "UI/UX",
  },
  {
    id: 9,

    image: logsafeImage,
    imagePlaceholder: logsafeImagePlaceholder,
    title: "Logsafe",
    Banner: Bannerlogosafe,
    Paragraph:
      "Logsafe is a product name that was for a new startup based of Bengaluru. The team was keen to build an app that served the Mall owners to ease the traffic congestion in the parking",
    TheChallenge:
      "As a startup the ideations were to develop a parking assist app for Malls. ",
    TheChallenge2:
      "Idea shifted to the attendance monitoring application as covid hit the same time.",
    TheChallenge3:
      "Developing an application on how it may be used and the structuring was of a major challenge.",
    TheChallenge4:
      "Multiple levels of brainstorming and ideations on how customer would reach and accept was more on concern . ​",
    TheChallenge5:
      "Needed to build strong branding and UI / UX that supported the application",
    TheChallenge6: "Logo and stationery, website etc to be built",
    ourrole1: "Logo Conceptualisation",
    ourrole2: "Logo Design",
    ourrole3: "Corporate Brand Identity manual",
    ourrole4: "Website design and development",
    ourrole5: "Brochure and Product info material",
    ourrole6: "UI / UX design for almost 400 plus pages",
    ourrole7: "Application ideation",
    ourrole8: "Structural design / process flow",
    ourrole9: "HTMLisation of the UX",
    ourrole10: "Printing of Brochures",
    task1:
      "Logsafe is a product name that was for a new startup based of Bengaluru. The team was keen to build an app that served the Mall owners to ease the traffic congestion in the parking lot, however the idea shifted to build an application that would help companies to solve their attendance issues.",
    img3: Imagelogosafe3,
    img1: Imagelogosafe1,
    img2: Imagelogosafe2,

    img4: Imagelogosafe4,
    img5: Imagelogosafe5,
    img6: Imagelogosafe6,
    category: "UI/UX",
  },

  {
    id: 10,
    image: herbartizanImage,
    imagePlaceholder: herbartizanImagePlaceholder,
    title: "Herbartizan",
    Banner: BannerHerbaertizan,
    Paragraph: "Produces world's finest quality botanical ingredients.",
    TheChallenge: "To create simple, unique differentiat from commercial brand",
    TheChallenge2: "To design a new brand identity ​",
    TheChallenge3: "Remain true to the heritage",
    TheChallenge4:
      "Create an aesthetically pleasing food grade packaging solutions",
    ourrole1: "Logo designing",
    ourrole2: "Website",
    ourrole3: "Stationery design",
    ourrole4: "Product packaging",
    ourrole5: "Photography",
    ourrole6: "Pricelist",
    ourrole7: "Product info materials",
    ourrole8: "Event specific graphic design and development",
    taskheading: "HerbArtizan",
    task1:
      "HerbArtizan is dedicated to producing world's finest quality botanical ingredients. They are aspire to create pharmacopoeia quality in organic certified botanicals. Specialized in standardized botainical powders and premium quality tea cut botanicals.",
    img3: ImageHerb3,
    img2: ImageHerb2,
    img1: ImageHerb1,

    // img4: ImageHerb4,
    // img5: ImageHerb5,
    category: "UI/UX",
  },

  {
    id: 11,
    image: agsamImage,
    title: "Agsam",
    imagePlaceholder: agsamImagePlaceholder,
    Banner: BannerAgsam,
    Paragraph:
      "EnergyTech startup focused on data analytics of Batteries, Energy storage and Power converters",
    TheChallenge:
      "Agsam is in a new gen business model that is new industry segment for us to consider.",
    TheChallenge2:
      "Proper market research and study of the business model was an important task",
    TheChallenge3:
      "Competition analysis as well as Branding on the industry segment was a challenge as client insisted on a particular approach",
    TheChallenge4: "We had to squeeze in with minimal given options.",
    ourrole1: "Logo design ",
    ourrole2: "Stationery design",
    ourrole3: "Website design",
    task1:
      "The word AGSAM is derived from 2 words – Agastya and Samhita, where Agastya was a Rishi who formulated concepts of storing power in an unique manner in the ancient times. It’s believed that he formulated several theories through his divine powers and many of them are in the scriptures.",
    task2:
      "Our Graphic Device encapsulates Agsam’s core proposition and value. The AGSAM Logo is formed with 2 elements Electric streak and Plus.",
    img1: ImageAgsam1,
    img2: ImageAgsam2,
    img3: ImageAgsam3,
    category: "UI/UX",
  },

  {
    id: 12,
    image: centumImage,
    imagePlaceholder: centumImagePlaceholder,
    title: "Centum",
    Banner: BannerCentum,
    Paragraph:
      "The worlds largest electronic manufacturing company that produces components of high caliber for the defence",
    TheChallenge: "Centum approaches us for a corporate movie production​​",
    TheChallenge2: "Film needed to be shot at 3 international locations ​",
    TheChallenge3:
      "Develop Creatives for their Stalls at Defence Expo participation ​",
    TheChallenge4: "Concept and design of Print ads for Magazines.​​",
    TheChallenge5: "Redesign the Website​​",
    ourrole1: "Corporate Video Development",
    ourrole2: "Website design and development",
    ourrole3: "Brochure design ",
    ourrole4: "Interior Branding",
    ourrole5: "Exhibition Stall Panel Designs ",
    ourrole6: "Facility Branding ",
    ourrole7: "Signage installations",
    ourrole8: "Corporate Merchandise supply ",
    ourrole9: "Event participation",
    ourrole10: "collateral designs",
    ourrole11: "Relook of Logo color",
    ourrole12: "scheme and proposed",
    task1:
      "Centum is one of the worlds largest electronic manufacturing company that produces components of high caliber for the defence, aerospace and space critical missions. With presence over 4 plus countries Centum is a proud Indian originated company making its mark across the globe.",
    img1: ImageCentum1,
    img2: ImageCentum2,
    img3: ImageCentum3,
    img4: ImageCentum4,
    category: "UI/UX",
  },

  {
    id: 13,
    image: fiberexcellenceImage,
    title: "Fiberxcellence",
    imagePlaceholder: fiberexcellenceImagePlaceholder,
    Banner: Bannerfiberexcellence,
    Paragraph:
      "Fiberxellence is a company based in France that focuses on industry segments that are providing fiber based materials, especially nonwovens, specialties and technical textiles and they assist in customized, differentiated and competitive solutions for a nice set of customers globally.",
    TheChallenge:
      "A start up in France needed to make its presence in the paper industry",
    TheChallenge2: "Propose a Logo that communicates the industry vertical",
    TheChallenge3: "Fiberxellence as an organisation needed to make an impact",
    TheChallenge4:
      "To develop brand identity that would build a strong global presence",
    TheChallenge5: "Build platforms that can reach its audience",
    TheChallenge6: "Website design",
    TheChallenge7: "Stationary design",
    TheChallenge8: "Newsletter communication design",
    TheChallenge9: "Presentation deck development",
    ourrole1: "Logo Design",
    ourrole2: "Stationery design",
    ourrole3: "Brochure design",
    ourrole4: "Signage design",
    ourrole5: "Website development",
    ourrole6: "Newsletter editable formats",
    ourrole7: "Word/ PPT template",
    ourrole8: "Social Media Presence",
    task1:
      "Fiberxellence is a company based in France that focuses on industry segments that are providing fiber based materials, especially nonwovens, specialties and technical textiles and they assist in customized, differentiated and competitive solutions for a nice set of customers globally. They come with a strong vision of providing customized and sustainable solutions to global industry partners across the value chain of high-performance fiber-based materials.",
    img2: Imagefiverexcellance2,
    img1: Imagefiverexcellance1,

    img4: Imagefiverexcellance4,
    img3: Imagefiverexcellance3,
    category: "PRESENTATION",
  },

  {
    id: 14,
    image: toyotaImage,
    imagePlaceholder: toyotaImagePlaceholder,
    title: "Toyota",
    category: "VIDEOS",
    Banner: Bannertoyota,
    Paragraph:
      "The Worlds largest Car Manufacturers and they are known as Toyota Kirloskar Motors in India.",
    TheChallenge:
      "Concept creations for presentations and template development based on a standard that is well accepted across the Toyota fraternity",
    TheChallenge2:
      "Design and develop Innovative product brochures that would be made available online",
    TheChallenge3:
      "Communicating with the mass about maintaining their vehicles at various situations and how they can minimize the effort on reducing the service costs",
    TheChallenge4:
      "Stall designs that are portable and modular for event participations abroad.​",
    TheChallenge5:
      "Concept ,ideation and creative support for their internal communications​",
    TheChallenge6:
      "Educating the employees on the safety norms and adherence through experience methodology",
    ourrole1: "Logo designs for various initiatives.",
    ourrole2: "Vehicle Branding",
    ourrole3: "Flip Book design and development",
    ourrole4: "Brochure design",
    ourrole5: "Panel / infographic design",
    ourrole6: "Website design for a project",
    ourrole7: "Corporate Presentations",
    ourrole8: "Virtual Reality",
    ourrole9: "Video Production",
    ourrole10: "Training Videos",
    ourrole11: "Powerpoint presentations",
    ourrole12: "Poster designs",
    task1:
      "Toyota is one of the Worlds largest Car Manufacturers and they are known as Toyota Kirloskar Motors in India. Renowned reputation of Quality Durability and Reliability (QDR) has led its steady growth in the Indian automotive market. Over 1 million Indians are part of the ever-growing Toyota family. Toyota has constantly and consistently exhibited its relentless commitment to add value through its world-class systems and processes.",
    img1: Imagetoyota1,
    img2: Imagetoyota2,
    img3: Imagetoyota3,
    img4: Imagetoyota4,
    img5: Imagetoyota5,
  },
  {
    id: 15,
    image: aronImage,
    imagePlaceholder: aronImagePlaceholder,
    title: "Aron",
    Banner: BannerAron,
    Paragraph:
      "One of the second largest fluorescent color pigment manufacturers  in the world.",
    TheChallenge: "Aron has distinct fluorescent color that is its USP. ​",
    TheChallenge2:
      "Customer wants the colour to be highlighted in all communications​",
    TheChallenge3:
      "Printing of Fluorescent colors is not easy on the offset printing unless there is a special approach to it. And hardly anyone supports.",
    TheChallenge4:
      "Had to find out a unique way that speaks about the company’s USP and gain more importance​",
    TheChallenge5:
      "Want to create a Brand identity of itself among its competitors worldwide.",
    task1:
      "One of the second largest fluorescent color pigment manufacturers in the world. Aron approached us for developing the creatives for one of the events they were participating.The management wanted an approach that would be classy and memorable making it the best stall. ",
    task2:
      "We delivered a top-notch design concept that not only inspired but made an impression in the event making it the best visible stall.",
    ourrole1: "Event Stall designs",
    ourrole2: "Design of Business cards",
    ourrole3: "Product packaging ",
    img1: ImageAron1,
    img2: ImageAron2,
    img3: ImageAron3,
    category: "BRANDING",
  },
  {
    id: 16,
    image: continentalImage,
    imagePlaceholder: continentalImagePlaceholder,
    title: "Continental",
    Banner: Bannercontinental,
    Paragraph:
      "Development of pioneering technologies and services for sustainable and connected mobility of people and their goods.",
    TheChallenge: "To build a strong brand recognition",
    TheChallenge2: "To postion Netrack as premium company",
    TheChallenge3:
      "To develop brand identity that would merge company with strong global presence",
    TheChallenge4: "To increase company growth​",
    ourrole1: "Stationery design",
    ourrole2: "Brochure",
    ourrole3: "Sinages",
    ourrole4: "Event backdrop",
    ourrole5: "Standee",
    ourrole6: "Product video",
    ourrole7: "Corporate Presentations",
    ourrole8: "Deliered Merchandise",
    task1:
      "Continental enjoys a global reputaion as a materials specialist and development partner with innovative products and intelligent systems that make use of rubber, plastic and combination of materials such as metals, fabric, textiles, glass and electronic components.",
    img1: Imagecontinental1,
    img4: Imagecontinental4,
    img3: Imagecontinental3,

    img2: Imagecontinental2,
  },
  {
    id: 17,
    image: triveniImage,
    imagePlaceholder: triveniImagePlaceholder,
    title: "Triveni",
    Banner: Bannertriveni,
    Paragraph:
      "Triveni turbines is one of the worlds largest and fastest growing turbine manufacturers",
    TheChallenge:
      "Corporate movie was developed by an agency that was not scripted well and the montage was really daunting to work on a nice output",
    TheChallenge2:
      "Shoot of 2 locations with a stipulated timeline was necessary.",
    TheChallenge3:
      "Development of a Corporate Presentation deck with styling was asked for .",
    TheChallenge4: "The deck was old and not of a good outline ​.​",
    TheChallenge5: "Corporate Gifting was sought .",
    TheChallenge6:
      "Customized Dairies and Tabletop Calendars were asked if we were able to design and deliver.​",
    ourrole1:
      "Complete branding and development of Brand Template for Presentations",
    ourrole2: "Digital Business Cards for employees",
    ourrole3: "Brochure design ",
    ourrole4: "Panel / infographic design",
    ourrole5: "Corporate Video Production",
    ourrole6: "Powerpoint presentations",
    ourrole7: "Corporate Merchandise",
    ourrole8: "New Year Desktop Calendars",
    ourrole9: "Organiser design print and supply",
    ourrole10: "Notebooks design print and supply",
    ourrole11:
      "Facility ShootTranslation of Videos in 2 international languages- Russian and Spanish",

    task1:
      "Triveni turbines is one of the worlds largest and fastest growing turbine manufacturers. It is a dominant Industrial Steam Turbine manufacturer with a global footprint spanning over 75 countries. The Company is the market leader in India with a dominant 60% market share. It also offers comprehensive aftermarket services covering the entire lifecycle of turbines. TTL operates in higher capacity range upto 100 MW turbines.",

    category: "Branding",
    img1: Imagetriveni1,
    img2: Imagetriveni2,
    img3: Imagetriveni3,
    img4: Imagetriveni4,
    img5: Imagetriveni5,
    img7: Imagetriveni7,
    img8: Imagetriveni8,
  },
  {
    id: 18,
    image: golden_scoopImage,
    imagePlaceholder: golden_scoopImagePlaceholder,
    title: "Golden Scoop",
    Banner: BannerGolden,
    Paragraph: "",
    TheChallenge:
      "To create simple, unique differentiat from commercial brand​​",
    TheChallenge2: "To design a new brand identity ​",
    TheChallenge3: "Remain true to the heritage",
    TheChallenge4:
      "Create an aesthetically pleasing food grade packaging solutions​",
    ourrole1: "Logo designing",
    ourrole2: "Website",
    ourrole3: "Stationery design",
    ourrole4: "Product packaging",
    ourrole5: "Photography",
    ourrole6: "Pricelist",
    ourrole7: "Product info materials",
    ourrole8: "Event specific graphic design and development",
    task1: "Content Pending",
    category: "Branding",
    img1: ImageGoldenscope1,
    img2: ImageGoldenscope2,
    img3: ImageGoldenscope3,
    img4: ImageGoldenscope4,
  },
  {
    id: 19,
    image: ohmiumImage,
    imagePlaceholder: ohmiumImagePlaceholder,
    title: "Ohmium",
    category: "Branding",
    Banner: Bannerohmium,
    Paragraph:
      "Specilized in hydrogen technology working on scalable hydrogen manufacturing for various industry verticals.",
    TheChallenge:
      "Create a Logo for an organisation where the brief was restricted to the industry vertical​",
    TheChallenge2:
      "Suggested to use only a few elements which they were particular.",
    TheChallenge3:
      "We were asked for ideations for a completely new industry vertical.",
    TheChallenge4:
      "Brand Identity needed to be built on colours which were in contrast.​",
    TheChallenge5:
      "Developing a 3D rendered product visual that was not born, only some imaginations shared with us to shape the product.",
    TheChallenge6: "Handholding on the branding aspects",
    TheChallenge7: "Create Brand awareness through website, videos.​",
    TheChallenge8:
      "Developing a 3D rendered product visual that was not born, only some imaginations shared with us to shape the product.​",
    ourrole1: "Logo Design",
    ourrole2: "Corporate Brand Identity manual",
    ourrole3: "3D renderings of the Product",
    ourrole4: "Internal Signages",
    ourrole5: "Video productions for various approach",
    ourrole6: "Website design and development",
    ourrole7: "Brochure and Product info material",
    ourrole8: "Office Branding",
    ourrole9: "Internal and External branding",
    ourrole10: "External Signages",
    ourrole11: "Facility Shoots",
    ourrole12: "Product Photography",
    taskheading: "Ohmium",
    taskimage: "taskohmium",
    task1:
      "Ohmium is a company that is specializing in hydrogen technology working on scalable hydrogen manufacturing for various industry verticals. The company did not have a logo but the vision was clear that they wanted to make a mark in the green hydrogen manufacturing in India. Though the promoters are from the USA, we were chosen to be their design agency for the Brand building",
    img1: Imageohmium1,
    img2: Imageohmium2,
    img3: Imageohmium3,

    img5: Imageohmium5,
    img6: Imageohmium6,
    img7: Imageohmium7,
    img4: Imageohmium4,
  },

  {
    id: 20,
    image: indodentImage,
    imagePlaceholder: indodentImagePlaceholder,
    title: "Indodent",
    Banner: Bannerindodent,
    Paragraph: "content to be provided",
    TheChallenge: "To build a strong brand recognition",
    TheChallenge2: "To postion Netrack as premium company",
    TheChallenge3: "To increase company growth​",
    ourrole1: "Stationery design",
    ourrole2: "Brochure",
    ourrole3: "Sinages",
    ourrole4: "Event backdrop",
    ourrole5: "Standee",
    ourrole6: "Product video",
    ourrole7: "Corporate Presentations",
    img3: Imageindodent3,
    img1: Imageindodent1,
    img2: Imageindodent2,
    category: "Branding",
  },

  {
    id: 21,
    image: netrackImage,
    imagePlaceholder: netrackImagePlaceholder,
    title: "Netrack",
    Banner: BannerNetrack,
    Paragraph:
      "The data centers with associated paraphernalia of storage systems, networking and telecommunications.",
    TheChallenge: "To build a strong brand recognition",
    TheChallenge2: "To postion Netrack as premium company",
    TheChallenge3:
      "To develop brand identity that would merge company with strong global presence",
    TheChallenge4: "To become a market leader​",
    TheChallenge5: "To increase company growth​",
    ourrole1: "Logo designing",
    ourrole2: "Stationery design",
    ourrole3: "Brochure",
    ourrole4: "Product catalog",
    ourrole5: "Sinages",
    ourrole6: "Environmental graphics",
    ourrole7: "Event backdrop",
    ourrole8: "Standee",
    ourrole9: "Product video",
    ourrole10: "Corporate Presentations",
    task1:
      "Managing director of the company needed to rework the brand and recognized the importance of this as to push the company forward to the next level",
    task2:
      "They therefore approached Srishti to rebrand Netrack toward the idea of refreshed brand identity and a new brand strategy for the company",
    img5: ImageNetrack5,
    img4: ImageNetrack4,

    img2: ImageNetrack2,
    img3: ImageNetrack3,
    img1: ImageNetrack1,
  },
];
export default imageData;
