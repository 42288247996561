import React, { useState } from "react";
import { Form, Col, Row, FormControl } from "react-bootstrap";

import "./Careerform.css";

const CareerForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [category, setCategory] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("category", category);
    formData.append("attachment", attachment);
    formData.append("message", message);
    formData.append("type", "career");

    try {
      const response = await fetch("http://localhost:5001/sendEmail", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        console.log("Email sent successfully");
      } else {
        console.log("Failed to send email");
      }
    } catch (error) {
      console.log("Error while sending email", error);
    }
  };

  return (
    <div
      className="container-fluid aos-init aos-animate my-5"
      data-aos="fade-up"
    >
      <div className="row">
        <div className="col-lg-4 col-md-12 col-sm-12 enq">
          <p style={{ paddingLeft: "2rem" }}>Apply Here</p>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <Form
            method="POST"
            id="career-form"
            className="cont-form"
            onSubmit={handleSubmit}
            style={{ width: "70%", paddingLeft: "2rem" }}
          >
            <Row>
              <Col className="col-lg-6 col-sm-12">
                <Form.Group className="form-group input-with-margin">
                  <Form.Control
                    className="input-field required"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="First Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col className="col-lg-6 col-sm-12">
                <Form.Group className="form-group input-with-margin">
                  <Form.Control
                    className="input-field required"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="col-lg-6 col-sm-12">
                <Form.Group className="form-group input-with-margin">
                  <Form.Control
                    className="input-field required"
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col className="col-lg-6 col-sm-12">
                <Form.Group className="form-group input-with-margin">
                  <Form.Control
                    as="select"
                    className="input-field required"
                    id="category"
                    name="category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option value="">Select Job</option>
                    <option value="Wordpress Developer">
                      Wordpress Developer
                    </option>
                    <option value="Web Designer">Web Designer</option>
                    <option value="FrontEnd Developer">
                      FrontEnd Developer
                    </option>
                    <option value="Graphic Designer">Graphic Designer</option>
                    <option value="UI/UX Designer">UI/UX Designer</option>
                    <option value="PPT Designer">PPT Designer</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="col-lg-12 col-sm-12 mb-4">
                <label style={{ margin: "0 0 0.8rem 2.3rem" }}>
                  Attach resume as .pdf, .doc, .odt, or .txt (limit 5MB)
                </label>
                <Form.Group className="form-group input-with-margin">
                  <FormControl
                    className="input-field "
                    type="file"
                    name="attachment" // Make sure this name matches with the field name expected by Multer
                    id="file"
                    accept=".pdf,.docx"
                    onChange={(e) => setAttachment(e.target.files[0])}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="col-lg-12 col-sm-12">
                <Form.Group className="form-group input-with-margin">
                  <textarea
                    className="input-field required"
                    name="message"
                    id="message"
                    rows={5}
                    placeholder="Enquiry"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="col-lg-12 col-sm-12">
                <button className="common_btn" type="submit" name="btn-submit">
                  <span className="mt-4 mb-4 p-0">Submit</span>
                </button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CareerForm;
