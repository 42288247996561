import React from "react";
import { Link } from "react-router-dom";
import "./ourBranding.css";
import { PathName } from "../Utilities/getPathName";

const Branding = (props) => {
  const pathName = PathName();
  const serviceCardName = pathName.includes("service") ? "services-card" : "";

  return (
    <div className="container-fluid" data-aos="fade-right">
      <div className="row btnnn2">
        <div className="col-lg-12">
          <Link
            to={`/branding/${props.id}`}
            className={`btnnn1 test-completeddd ${serviceCardName}`}
          >
            <div className="brand-content">
              <h4>{props.name}</h4>
              <h5>{props.description}</h5>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Branding;
