import React, { useState, useEffect, useRef } from "react";

import linkedin from "../../Assets/images/linkedin.png";
import instagram from "../../Assets/images/instagram.png";
import facebook from "../../Assets/images/facebook.png";
import youtube from "../../Assets/images/youtube.png";
import behance from "../../Assets/images/behance.png";
import pinterest from "../../Assets/images/pinterest.png";

import "./Fotter.css";

const Fotter = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const sitesealRef = useRef(null);

  useEffect(() => {
    // Update the current year
    setCurrentYear(new Date().getFullYear());

    const godaddyScript = document.createElement("script");
    godaddyScript.async = true;
    godaddyScript.src =
      "https://seal.godaddy.com/getSeal?sealID=AYGn4UxcjClxfX01smXOtGekeKRAIFiGPvEPVV0XxyA0yY4xe6ofwjqZ4WF3";

    // Check if the script with id 'siteseal' already exists using useRef
    if (!sitesealRef.current) {
      // Append the script below the social media icons
      const socialMediaIcons = document.getElementById("social-media-icons");
      socialMediaIcons.appendChild(godaddyScript);

      // Update the useRef to indicate that the script has been executed
      sitesealRef.current = true;
    }
  }, [sitesealRef]);

  return (
    <div className="container-fluid">
      <div className="fotter">
        <div className="row">
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 ">
            <h5>Talk us: </h5>
            <a href="tel:9845533311" className="d-inline-block">
              + 91 98455 33311
            </a>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <h5>Write to us at : </h5>{" "}
            <a
              href="mailto:enquiry@srishtiindia.com"
              className="d-inline-block"
            >
              enquiry@srishtiindia.com
            </a>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <h5>Follow us : </h5>
            <div
              className="social-img d-flex align-items-center"
              id="social-media-icons"
            >
              <a
                href="https://www.facebook.com/srishtiindia"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={facebook}
                  alt="srishtiindia facebook"
                  height="28"
                  width="28"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/srishtiindia/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={linkedin}
                  alt="srishtiindia linkedin"
                  height="28"
                  width="28"
                />
              </a>
              <a
                href="https://instagram.com/srishtiindia"
                target="_blank"
                rel="noreferrer"
                style={{ paddingTop: "3.5px" }}
              >
                <img
                  src={instagram}
                  alt="srishtiindia instagram"
                  height="28"
                  width="28"
                />
              </a>
              <a
                href="https://in.pinterest.com/srishtiindia/"
                target="_blank"
                rel="noreferrer"
                style={{ paddingTop: "2.5px" }}
              >
                <img
                  src={pinterest}
                  alt="srishtiindia behance"
                  height="28"
                  width="28"
                />
              </a>
              <a
                href="https://www.behance.net/satyasar"
                target="_blank"
                rel="noreferrer"
                style={{ paddingTop: "2.5px" }}
              >
                <img
                  src={behance}
                  alt="srishtiindia behance"
                  height="28"
                  width="28"
                />
              </a>
              <a
                href="https://www.youtube.com/@SrishtiFilm"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={youtube}
                  alt="srishtiindia youtube"
                  height="28"
                  width="28"
                />
              </a>

              {/* <span
                id="siteseal"
                className="d-inline-flex align-middle ps-2"
              ></span> */}
            </div>
          </div>
        </div>
        <div className="text-center mt-5">
          &copy; 2013- {currentYear}{" "}
          <span className="fw-semibold companyName">
            SrishtiIndia Design Studio Pvt. Ltd.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Fotter;
