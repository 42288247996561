const Greetings = (hour) => {
  let greeting;

  if (hour >= 1 && hour < 12) {
    greeting = "MORNING";
  } else if (hour >= 12 && hour < 16) {
    greeting = "AFTERNOON";
  } else {
    greeting = "EVENING";
  }

  return greeting;
};

export default Greetings;
