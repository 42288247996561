import React, { useEffect } from "react";
import "./Items.css";
import { Link } from "react-router-dom";

const Items = (props) => {
  useEffect(() => {
    const blurredImages = document.querySelectorAll(".blur-load");

    blurredImages.forEach((imgContainer) => {
      const img = imgContainer.querySelector("img");

      function loaded() {
        imgContainer.classList.add("loaded");
      }

      img.complete ? loaded() : img.addEventListener("load", loaded);
    });
  }, []);

  return (
    <div className="container-fluid">
      <div className="row ite-css">
        <div className="col-lg-12">
          <figure className="snip1577">
            <Link
              to={`/details/${props.id}`}
              className="d-block blur-load img-container"
              style={{
                backgroundImage: `url("${props.placeholder}")`,
              }}
            >
              <img src={props.img} alt={props.alt} className="h-100" />
            </Link>
          </figure>
        </div>
      </div>
    </div>
  );
};

export default Items;
