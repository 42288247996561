import React, { createContext } from "react";
import ContentService from "../../Assets/Datas/ContentService";

export const ServiceContext = createContext();

const ServiceContextProvider= (props)=>{
  const contextValue={ContentService}

  return(
     <ServiceContext.Provider value={contextValue}>
        {props.children}
     </ServiceContext.Provider>
  )
}
export default ServiceContextProvider;