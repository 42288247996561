import { createContext, useEffect, useState } from "react";
import { imageData } from "./../../Assets/Datas/imageData";

export const WorkContext = createContext();

const WorkContextProvider = (props) => {
  const [workData, setWorkData] = useState([]);
  useEffect(() => {
    const fetchData = () => {
      new Promise((resolve) => setTimeout(resolve, 1000))
        .then(() => setWorkData(imageData))
        .catch((error) => console.log("error is :", error));
    };
    fetchData();
  }, []);
  const contextValue = { workData };
  return (
    <WorkContext.Provider value={contextValue}>
      {props.children}
    </WorkContext.Provider>
  );
};

export default WorkContextProvider;
