let hasPreloaded = false;

export function preloadImages(imageLinks) {
  if (hasPreloaded) return;

  imageLinks.forEach((src) => {
    const link = document.createElement("link");
    link.rel = "preload";
    link.href = src;
    link.as = "image";
    document.head.appendChild(link);
  });

  hasPreloaded = true;
}
