import React from "react";

import { contactContent } from "../../Assets/Datas/ContentData";
import { useState, useEffect } from "react";
import Form from "../Form/Form";
import "./Contact.css";
import { getHours } from "../Utilities/getHours";
import getGreetings from "../Utilities/getGreetings";
import { useLocation } from "react-router-dom";

const Contact = () => {
  const [greeting, setGreeting] = useState("");
  const currentHour = getHours();
  const location = useLocation();
  const currentPage = location.pathname.substring(1).toLowerCase();

  useEffect(() => {
    const updatingGreeting = () => {
      const currentGreeting = getGreetings(currentHour);
      setGreeting(currentGreeting);

      const contactPage = document.querySelector(".contact-page");

      if (currentPage === "contact") {
        currentHour >= 16 && currentHour <= 24
          ? contactPage.classList.add("dark-bg")
          : contactPage.classList.remove("dark-bg");
      }
    };

    updatingGreeting();

    const updateEveryTime = setInterval(updatingGreeting, 6000);
    return () => clearInterval(updateEveryTime);
  }, [currentHour, currentPage]);

  return (
    <>
      <div className="container-fluid contact-section">
        <div className="row">
          <div className="col-lg-3 col-md-5 col-sm-12">
            <h1 className="section-heading">{contactContent.heading}</h1>
          </div>

          <div className="col-lg-9 col-md-7 col-sm-12 typoo">
            <h1 className="greetingg">GOOD</h1>
            <br />
            <h2 className="greeting">{greeting}</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-5 col-sm-12"></div>
          <div className="con1 col-lg-9 col-md-7 col-sm-12 mb-5">
            <h2> Get in touch, let's turn your ideas into reality!</h2>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid">
      <div className="row" >
        <iframe style={{"background":"black"}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.9147680068877!2d77.58304160856989!3d12.913199387344248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15e10b2621a3%3A0x93500071408c5989!2sSrishti%20India%20Design%20Studio%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1709724242582!5m2!1sen!2sin" width={'100%'} height="500"  loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

        </div>
      </div> */}

      <Form />
    </>
  );
};

export default Contact;
