// src/contentData.js
import image2 from '../images/hiring.webp';

export const aboutContent = {
    heading: "About Us",
    
  };
  
    
  export const serviceContent = {
      heading: "Our Services",
      paragraph: "Explore our full range of in-house brand development, creative design, digital marketing, and other services.",
};
    
  
    export const careerContent = {
      heading: "Careers",
      paragraph: "We believe a good idea can come from anyone, and that listening sharpens our ability to connect with audiences.",
      images:[image2]
    };
  
  
    export const contactContent = {
      heading: "Contact",
      
    };
  
  
  
    
    export const abbContent = {
      heading: "ABB",
      paragraph: "Company ​that energizes the transformation of society and ​industry​.",
    };
  
  
    export const aronContent = {
      heading: "ARON",
      paragraph: "One of the second largest fluorescent color pigment manufacturers in the world.",
    };