import React, { useState } from "react";
import { Form, Col, Row } from "react-bootstrap";
import "./Form.css";
import emailjs from "@emailjs/browser";
import { getHours } from "../Utilities/getHours";
import getGreetings from "../Utilities/getGreetings";
import { useNavigate } from "react-router-dom";

const ContactForm = () => {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const currentHour = getHours();
  const greeting = getGreetings(currentHour).toLowerCase();

  const { name, email, phone, message } = formState;

  const handleChange = (e, formValue) => {
    setFormState((prevState) => ({
      ...prevState,
      [formValue]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

    const mailParams = {
      from_name: name,
      from_email: email,
      from_phone: phone,
      to_name: "Srishti India",
      message,
      reply_to: email,
      greeting,
    };

    emailjs
      .send(serviceID, templateID, mailParams, publicKey)
      .then((response) => {
        alert("Email sent successfully");

        navigate("/ThankYou");

        setFormState({
          name: "",
          email: "",
          phone: "",
          message: "",
        });

        setIsSubmitting(false);
      })
      .catch((error) => {
        console.log("Error sending email: ", error);
      });
  };

  return (
    <div
      className="container-fluid aos-animate aos-p contact-form"
      data-aos="fade-up"
    >
      <div className="row">
        <div className="col-lg-3"></div>
        <div className="col-lg-8 col-md-12 col-sm-12 enq">
          <p style={{ paddingLeft: "2rem" }}>Feel Free to Contact us</p>
          <Form
            id="contact-form"
            className="cont-form"
            style={{ width: "70%" }}
            onSubmit={handleSubmit}
          >
            <Row>
              <Col className="col-lg-6 col-sm-12">
                <Form.Group className="form-group input-with-margin">
                  <Form.Control
                    className="input-field required"
                    type="text"
                    placeholder="Name"
                    value={name}
                    autoComplete="on"
                    onChange={(e) => handleChange(e, "name")}
                    required
                  />
                </Form.Group>
              </Col>

              <Col className="col-lg-6 col-sm-12">
                <Form.Group className="form-group input-with-margin">
                  <Form.Control
                    className="input-field required"
                    type="email"
                    placeholder="Email"
                    value={email}
                    autoComplete="on"
                    onChange={(e) => handleChange(e, "email")}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="col-lg-12 col-sm-12">
                <Form.Group className="form-group input-with-margin">
                  <Form.Control
                    className="input-field required"
                    type="text"
                    placeholder="Phone"
                    value={phone}
                    autoComplete="on"
                    onChange={(e) => handleChange(e, "phone")}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="col-lg-12 col-sm-12">
                <Form.Group className="form-group input-with-margin">
                  <textarea
                    className="input-field required"
                    placeholder="Message"
                    value={message}
                    onChange={(e) => handleChange(e, "message")}
                    rows="4"
                    required
                  ></textarea>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="col-lg-12 col-sm-12">
                <button
                  className="common_btn"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Sending..." : "Submit"}
                </button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
