import React from 'react';

const WebDeveloperTab = () => {
  return (
    <div id="tab3" className="tabcontents">
             <div className="row">
                <div className="col-lg-12">

                <h3 className="mt-5">Web Developer</h3>

                   <div className="spacer1"></div>
                   <h4>Open Position : 2 Web developer</h4>

                  <h5>Relevant 2-4 years of experience working with product companies or consumer-facing applications.
                  </h5>

                  <h5>Collaborating with our designer and content writer to plan projects.</h5>
                  <h5>Create, design, develop, test, and maintain web based applications built on various web
                    technologies.</h5>
                  <h5>Assist in the collection and documentation of the end user's requirements, development of user
                    stories, and estimates.</h5>
                  <h5>Provide customized stylization and theming.</h5>
                  <h5>Ensure cross-browser compatibility across a variety of supported browsers.</h5>
                  <h5>Write the programming code, either from scratch, or by adapting existing website software and
                    graphics packages to meet business requirements.</h5>





                </div>

                <div className="col-lg-12">

                  <h4> Key Duties:</h4>
                  <h5>We’re looking for a high performing, skilled, and friendly technologist/PHP/Magento/WordPress
                      Programmer. Impress us with your potential,
                      skills, and attitude to exceed our high standards.</h5><br/>
                  <h5>Preferably BE, B.Tech (CSE/IT), MCA, M.Sc. (IT/CSE) graduates
                      Should have a minimum of 2 or more years of relevant experience. (You can still apply if you’re a
                      graduate who has working PHP experience and/or knowledge).</h5><br/>
                  
                  <p>Proven software development experience in PHP.</p><br/>
                  <p>Understanding of WordPress and Magento (optional) structures.</p><br/>
                  <p> Demonstrable knowledge of web technologies including HTML, CSS, Javascript, DOM, AJAX etc.</p><br/>
                  <p>Good knowledge of relational databases, version control tools and of developing web services.</p><br/>
                  <p>Experience in common third-party APIs (Google, Facebook, eBay etc.).</p><br/>
                  <p>Passion for best design and coding practices.</p><br/>
                  {/* <ul>
                    <li>We’re looking for a high performing, skilled, and friendly technologist/PHP/Magento/WordPress
                      Programmer. Impress us with your potential,
                      skills, and attitude to exceed our high standards.</li>
                    <li>Preferably BE, B.Tech (CSE/IT), MCA, M.Sc. (IT/CSE) graduates
                      Should have a minimum of 2 or more years of relevant experience. (You can still apply if you’re a
                      graduate who has working PHP experience and/or knowledge).</li>
                    <ul> */}
                      {/* <li> Proven software development experience in PHP.</li>
                      <li> Understanding of WordPress and Magento (optional) structures.</li>
                      <li> Demonstrable knowledge of web technologies including HTML, CSS, Javascript, DOM, AJAX etc.
                      </li>
                      <li> Good knowledge of relational databases, version control tools and of developing web services.
                      </li>
                      <li> Experience in common third-party APIs (Google, Facebook, eBay etc.).</li>
                      <li> Passion for best design and coding practices.</li>
                    </ul> */}

                  {/* </ul> */}
                  <h4>Remuneration:</h4> <br/>
                  <h5>Negotiable depending on relevant experience and coding test</h5>

                </div>


               </div>
            </div>
  );
};

export default WebDeveloperTab;
