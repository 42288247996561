import BannerBrand from "../images/brand.webp";
import BannerCreative from "../images/creative_design.webp";
import BannerPpt from "../images/corporate_ppt.webp";
import BannerPackage from "../images/product_package.webp";
import BannerVideo from "../images/Intro_Video.mp4";
import BannerDigital from "../images/digital.webp";
import BannerEnv from "../images/brand.webp";
import BannerSocial from "../images/social_media.webp";
import BannerAdv from "../images/adv.webp";

const ContentService = [
  {
    id: 1,
    name: "Branding",
    description: "Define your brand",
    descriptionService:
      "Unlock the power of a strong corporate brand with our comprehensive branding solution.",
    nameParagraph:
      "Elevate your business to new heights and leave a lasting impression on your audience with our expertly crafted branding. From creating captivating logos, designing visually stunning marketing materials, to developing a unique brand identity that resonates with your target market - we've got you covered.",
    banner: BannerBrand,
    nameNote:
      "At Srishti, we passionately believe that a strong brand is one that is distinctive, relevant, consistent and engaging. And we tirelessly endeavour to build brands that are readily recognizable and fully capable of addressing the needs and aspirations of your target audiences, with an unswervingly consistent voice across all channels: from your website to your marketing assets to your customer service. In essence, we deliver a highly seamless, vibrant and engaging brand experience for all your customers.",
    nameNote2:
      "In our quest to build a strong brand, one that is capable of evoking strong emotions in your audiences, we first seek to have a clear and comprehensive understanding of your target audience and what they value. Establishing the nature of your target audiences, enables us to build and nurture a brand that resonates with them. Secondly, we seek to be consistent in building a brand, right from deploying the same colours, fonts and messaging across all the marketing assets. Finally, we embark upon devising communication that is resonant, enticing and meaningful for your audiences.",
    nameNote3:
      "Contact us today to build and elevate a brand that your audiences love to engage with. For there is no better way to surge ahead of your competitors.",
    link: "branding",
  },
  {
    id: 2,
    name: "Creative Designs",
    descriptionService:
      "Creative designs that uplift and are visually captivating for a lasting impression",
    description: "Passionate about designs",
    nameParagraph:
      "Good design equals good business. It’s this irrefutable principle that we at Srishti wholeheartedly embrace.",
    banner: BannerCreative,
    nameNote:
      "Thus, each design that we create is the consequence of rigorous research and concept development. It is precisely this fastidious attitude to design that enables us to produce work that makes a significant difference to your branding and marketing efforts.",
    nameNote2: "Our creative design services include:",
    nameNote3:
      "Logo design : Your logo is the first impression you'll make on potential customers. We bring focused attention to every detail, from colour choice to font and style, to build your brand.",
    nameNote4:
      "Graphic design : Graphic designing we do are for a variety of purposes, including print, web and social media that are always of high-quality and eye-catching.",
    nameNote5:
      "Space design : We design spaces that are both functional and aesthetically pleasing. When you're looking for an office space branding, we can help you create a space that you'll love.",
    nameNote6:
      "Product packaging design : With our expertise in innovative packaging concepts that is attractive and informative, we look to ensure that the key part of your brand identity scales high.",
    nameNote7:
      "Product packaging design : With our expertise in innovative packaging concepts that is attractive and informative, we look to ensure that the key part of your brand identity scales high.",
    nameNote8:
      "Environmental graphics: Our team builds innovative concepts of graphics, architecture, art and prints that are combined as a way to enhance the user experience in the built environment.",
    nameNote9:
      "branding and promotion : We create event branding and promotion materials that will help you get the word out about your event. Our materials will help you create a buzz and attract attendees.",
    nameNote10:
      "Our designers are skilled and experienced, and they're passionate about creating great designs. We're confident that we can deliver the best possible results for your project.",
    nameNote11:
      "Contact us today to learn more about our creative design services.",
    link: "design",
  },
  {
    id: 3,
    name: "Corporate PPT's",
    descriptionService:
      "Communicating with success starts with a stunning and a compelling presentation. It is your first contact with potential client that should be captivating.",
    description: "Deploying inspired presentaions",
    nameParagraph:
      "At Srishti, we sift through reams of data to craft power point presentations that readily resonate with your audience. Our designers excel at narrating your story from scratch through communication that is strategy-led and brand-focussed.",
    banner: BannerPpt,
    nameNote:
      "At Srishti, we passionately believe that a strong brand is one that is distinctive, relevant, consistent and engaging. And we tirelessly endeavour to build brands that are readily recognizable and fully capable of addressing the needs and aspirations of your target audiences, with an unswervingly consistent voice across all channels: from your website to your marketing assets to your customer service. In essence, we deliver a highly seamless, vibrant and engaging brand experience for all your customers.",
    nameNote2:
      "In our quest to build a strong brand, one that is capable of evoking strong emotions in your audiences, we first seek to have a clear and comprehensive understanding of your target audience and what they value. Establishing the nature of your target audiences, enables us to build and nurture a brand that resonates with them. Secondly, we seek to be consistent in building a brand, right from deploying the same colours, fonts and messaging across all the marketing assets. Finally, we embark upon devising communication that is resonant, enticing and meaningful for your audiences.",
    nameNote3:
      "Contact us today to build and elevate a brand that your audiences love to engage with. For there is no better way to surge ahead of your competitors.",
    link: "ppt",
  },
  {
    id: 4,
    name: "Product Package",
    descriptionService:
      "A product's packaging is the gateway to any brand, let us make that impression unforgettable.",
    description: "Custom package designs",
    nameParagraph:
      "Our premium product packaging solutions are designed to captivate, protect, and elevate your products, ensuring they stand out on the shelf and in the hands of your customers.",
    banner: BannerPackage,
    nameNote:
      "Your product is unique, and your packaging should be standing out. We offer a wide range of customization options, from concept to design, materials and finishes to printing and supply, so you can create a packaging that reflects your brand's personality and values.",
    nameNote2:
      "Your product packaging is a reflection of your brand, and it can be a powerful marketing tool. Ready to enhance your product's appeal with stunning packaging? Contact us today to discuss your project, and let's embark on a journey to create packaging that sets your brand apart from the competition. Elevate, captivate, and protect your products with us - your packaging partner for success.",
    nameNote3:
      "Contact us today to build and elevate a brand that your audiences love to engage with. For there is no better way to surge ahead of your competitors.",
    link: "productpackaging",
  },
  {
    id: 5,
    name: "Video Production",
    description: "Visual depiction of your brand ",
    descriptionService:
      "Videos are the best means of communication for inspiring or converting your audience.",
    nameParagraph:
      "At Srishti, we delight in crafting compelling corporate films.",
    banner: BannerVideo,
    nameNote:
      "Given the significance that a corporate film assumes: as a visual depiction of your brand’s message, we bring all your considerable experience and expertise in video production to bear on the kind of films we make. The result? Highly original, on-brand corporate films that captivate the audiences for the entire duration.",
    nameNote2:
      "Whether it is an employer branding video, landmark video, or an explainer video, our team of accomplished script writers, editors, directors and motion graphic designers work in concert to serve up a corporate film that captures your story in an utterly memorable manner, while being unfailingly consistent with your brand.",
    nameNote3:
      "Contact us today to build and elevate a brand that your audiences love to engage with. For there is no better way to surge ahead of your competitors.",
    link: "videoproduction",
  },
  {
    id: 6,
    name: "Digital Solutions",
    description: "Online presence for brands",
    descriptionService: "Define your brand",
    nameParagraph:
      "In this day and age, it is imperative to have a strong and highly distinctive digital presence. This is especially essential as your consumers are always online. Our array of digital services is conceived to establish a compelling online presence, for your brand—a presence that is capable of carving an indelible place in the hearts and minds of your customers.",
    banner: BannerDigital,
    nameNote: "Our wide range of digital services:",
    nameNote2: "Website design",
    nameNote3: "Email marketing",
    nameNote4: "E-brochures",
    nameNote5: "Website design",
    nameNote6: "Video email marketing",
    nameNote7: "PowerPoint presentations",
    nameNote8:
      "Additionally, our digital team is also highly adept in creating user interfaces that that will engage your audiences and deliver results. We also extend social integration, so your brand is right where your consumers are, boasting of a strong presence on all the platforms favoured by your consumers.",
    nameNote9:
      "Contact us today to propel your business to the next level with our sought- after digital services.",
    link: "digitalsolution",
  },
  {
    id: 7,
    name: "Social Media",
    // description:"Visual depiction of your brand ",
    descriptionService:
      "In day to day trends Social media is the prime key to marketing providing a way to stay connected with your target audience.",
    nameParagraph:
      "With the right strategy and tools, we can make a powerful and effective marketing to boost your business. The key is to create a plan that is tailored to your company's goals and objectives .",
    banner: BannerSocial,
    nameNote:
      "Our social media experts collaborate to conduct thorough social media audits. It is necessary to identify where your present audience is and what stage we are to reach the right audience.",
    nameNote2:
      "The team at Srishti uses strategies to develop social calendars that are curated well enough to create an interesting and shareable content on a timely basis. The publishing team works closely with the creative team to post content on relevant channels.",
    nameNote3:
      "It is essential for us to work closely with key stakeholders in your brand to know the engagement module, how your profiles are performing etc and manage them with a right attitude and approach guiding them with proper content on a timely basis.",
    // link:"/videoproduction"
  },
  {
    id: 8,
    name: "Environmental Graphics",
    description: "Online presence for brands",
    descriptionService:
      "Creating experiences, way finding and communicating identity through our design principles shaping the office space into a creative space.",
    nameParagraph:
      "Envonmental Graphic Design (EGD) embraces many design disciplines including graphic, interior, landscape, and industrial design all concerned with the visual aspects of communicating identity and information, and shaping the idea of creating experiences that connect people to place.",
    banner: BannerEnv,
    nameNote:
      "Environmental graphics are designed in a manner that is eye-catching and engaging, while also serving a practical purpose. Common elements of environmental graphic design include signage, wall graphics, window graphics, floor graphics, wraps, wayfinding and sculptural elements. Environmental graphics can be used to create an engaging atmosphere in commercial, residential spaces as well as public spaces.",
    nameNote2:
      "By incorporating creative and visually appealing graphics into the built environment, our designers help to create an environment that is aesthetically pleasing and informative for people. Environmental graphics can also be in educational spaces, museums, libraries and universities to convey important information in an interesting and visually appealing way.",
  },
  {
    id: 9,
    name: "Advertising",
    // description:"Online presence for brands",
    descriptionService:
      "Let us plan and work towards your brand with the right mix of creative and messaging to achieve your goals  .",
    nameParagraph:
      "Any advertising campaign, be it tactical or strategic, defining and redefining goals and objectives, developing strategies, and evaluating advertising results are the processes involved.",
    banner: BannerAdv,
    nameNote:
      "Strategic Advertising Planning, Corporate Brand Identity and Integrated Marketing Communication consultancy take the priority focus for Srishti. For any company be it a Start-up to an established organisation, it needs strategy and systematic planning for its advertising requirement.",
    nameNote2:
      "By incorporating creative and visually appealing graphics into the built environment, our designers help to create an environment that is aesthetically pleasing and informative for people. Environmental graphics can also be in educational spaces, museums, libraries and universities to convey important information in an interesting and visually appealing way.",
    nameNote3:
      "Any advertising campaign, be it tactical or strategic, defining and redefining goals and objectives, developing strategies, and evaluating advertising results are the processes involved.",
  },
];
export default ContentService;
