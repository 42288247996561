import React, { useContext } from "react";
import AutoChangeContent from "../../Assets/Datas/Autochangecontent.js";
import "./Service.css";
import { serviceContent } from "../../Assets/Datas/ContentData.js";
import Branding from "../ourBranding/Branding.jsx";
import { ServiceContext } from "../Context/ServiceContext.js";

const Services = () => {
  const { ContentService } = useContext(ServiceContext);
  // const fileterAndOrderdIds=ContentService.filter(item=>{
  const includeIds = [1, 7, 3, 8, 5, 9, 2, 4];
  // return includeIds.includes(item.id);
  // })

  return (
    <>
      <div className="section service-section">
        <div className="container-fluid service">
          <div className="row service-page">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <h1> {serviceContent.heading} </h1>
            </div>
            <div className="col-lg-8 col-md-6 col-sm-12">
              <h4> {serviceContent.paragraph} </h4>
            </div>

            <div className="col-lg-9 col-md-6 col-sm-12" data-aos="fade-up">
              <h4>
                {" "}
                <AutoChangeContent content={[serviceContent]} />
              </h4>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row btnn2">
            {includeIds.map((id) => {
              const serviceCard = ContentService.find((item) => item.id === id);

              if (serviceCard) {
                return (
                  <div
                    key={serviceCard.id}
                    className="col-lg-6 col-md-6 sol-sm-12"
                  >
                    <Branding
                      name={serviceCard.name}
                      description={serviceCard.descriptionService}
                      key={serviceCard.id}
                      id={serviceCard.id}
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
