// src/AutoChangeContent.js
import React, { useState, useEffect } from "react";

const AutoChangeContent = ({ content }) => {
  const [contentIndex, setContentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setContentIndex((prevIndex) => (prevIndex + 1) % content.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [content]); // Include content as a dependency to handle changes

  return (
    <div>
      {content[contentIndex].image && (
        <img
          src={content[contentIndex].image}
          alt="Content Img"
          style={{ width: "100%", height: "auto" }}
        />
      )}
    </div>
  );
};

export default AutoChangeContent;
